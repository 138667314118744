import differenceInSeconds from 'date-fns/differenceInSeconds'

export default function getTimeLeft(date: Date | string, limit = 15 * 60) {
    const actualDate = typeof date === 'string' ? new Date(date) : date
    const diff = limit - differenceInSeconds(new Date(), actualDate)
    if (diff <= 0) {
        return null
    }
    const minutes = Math.floor(diff / 60)
    const seconds = diff % 60
    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
}
