import Chip, { ChipTypeMap } from '@mui/material/Chip'
import { graphql, useFragment } from 'react-relay'
import { OrderStateChip_order$key, OrderState } from '@/__generated__/OrderStateChip_order.graphql.ts'

const COLOR_BY_STATE: { [key in OrderState]: ChipTypeMap['props']['color'] } = {
    WAITING: 'info',
    ACCEPTED: 'primary',
    REJECTED: 'error',
    COMPLETED: 'primary',
}

export default function OrderStateChip(props: { order: OrderStateChip_order$key }) {
    const order = useFragment<OrderStateChip_order$key>(
        graphql`
            fragment OrderStateChip_order on Order {
                state
            }
        `,
        props.order,
    )

    return (
        <Chip
            variant={order.state === 'COMPLETED' ? 'filled' : 'outlined'}
            color={COLOR_BY_STATE[order.state]}
            size="small"
            label={order.state}
            sx={{ borderRadius: 1 }}
        />
    )
}
