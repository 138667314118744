/**
 * @generated SignedSource<<2dd2975aebd957751c621376ce11e634>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UserType = "Admin" | "Customer" | "Operator" | "Provider";
export type routerQuery$variables = {};
export type routerQuery$data = {
  readonly viewer: {
    readonly userType: UserType;
  } | null;
};
export type routerQuery = {
  response: routerQuery$data;
  variables: routerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userType",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6e2b087d0677bd1c0f54bae38d3e73a8",
    "id": null,
    "metadata": {},
    "name": "routerQuery",
    "operationKind": "query",
    "text": "query routerQuery {\n  viewer {\n    __typename\n    userType\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "3194fe12a73138a7732b3409108a388d";

export default node;
