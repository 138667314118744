import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Typography,
} from '@mui/material'
import { useState } from 'react'
import { useFormik } from 'formik'
import { number, object } from 'yup'
import DataGrid from '@/components/DataGrid.tsx'
import { fromGlobalId } from '@/utils/relay.ts'
import formatEuro from '@/utils/formatEuro.ts'
import { graphql, useFragment, useMutation } from 'react-relay'
import { OperatorTransactionsSection_order$key } from '@/__generated__/OperatorTransactionsSection_order.graphql.ts'
import { OperatorTransactionsSectionMutation } from '@/__generated__/OperatorTransactionsSectionMutation.graphql.ts'
import { GENERIC_ERROR_MESSAGE } from '@/config.ts'

interface IProps {
    order: OperatorTransactionsSection_order$key
}

export default function OperatorTransactionsSection(props: IProps) {
    const order = useFragment<OperatorTransactionsSection_order$key>(
        graphql`
            fragment OperatorTransactionsSection_order on Order {
                id
                state
                isOperatorViewer
                allowancesOrdered
                allowancesFilled
                transactions {
                    id
                    allowancesFilled
                    price
                }
            }
        `,
        props.order,
    )

    const [isDialogOpen, setIsDialogOpen] = useState(false)

    const [addTransaction] = useMutation<OperatorTransactionsSectionMutation>(graphql`
        mutation OperatorTransactionsSectionMutation($orderId: ID!, $allowancesFilled: Int!, $price: Decimal!) {
            addTransaction(input: { orderId: $orderId, allowancesFilled: $allowancesFilled, price: $price }) {
                order {
                    ...OperatorOrderDetails_order
                }
            }
        }
    `)

    const allowancesMax = order.allowancesOrdered - (order.allowancesFilled || 0)

    const formik = useFormik({
        initialValues: {
            allowances: 0,
            price: 0,
            submit: null,
        },
        validationSchema: object({
            allowances: number()
                .required('EUAs are required')
                .positive('The value must be a positive number')
                .integer('The value must be an integer')
                .max(allowancesMax, `The value must be less than ${allowancesMax}`),
            price: number().required('Price is required').positive('The value must be a positive number'),
        }),
        onSubmit: (values, helpers) => {
            addTransaction({
                variables: { orderId: order.id, allowancesFilled: values.allowances, price: values.price },
                onCompleted: (data, errors) => {
                    helpers.setSubmitting(false)
                    if (errors || !data.addTransaction?.order) {
                        helpers.setErrors({ submit: GENERIC_ERROR_MESSAGE })
                    } else {
                        closeDialog()
                    }
                },
                onError: () => {
                    helpers.setSubmitting(false)
                    helpers.setErrors({ submit: GENERIC_ERROR_MESSAGE })
                },
            })
        },
    })

    function closeDialog() {
        formik.resetForm()
        setIsDialogOpen(false)
    }

    const gridStatusMessage = !order.transactions
        ? 'There was an error'
        : order.transactions.length === 0
        ? 'There are no transactions'
        : ''

    return (
        <>
            <Typography variant="h5" textAlign="left" mb={2}>
                Transactions
            </Typography>

            <DataGrid
                data={order.transactions || []}
                customMessage={gridStatusMessage}
                columns={[
                    { title: 'Transcation ID', key: 'id', render: (t) => fromGlobalId(t.id).id },
                    { title: 'No of EUA', key: 'allowancesFilled' },
                    { title: 'Price', key: 'price', render: (t) => formatEuro(t.price) },
                    {
                        title: 'Transaction Price',
                        key: 'total',
                        render: (t) => formatEuro(t.allowancesFilled * t.price),
                    },
                ]}
            />

            {order.state === 'ACCEPTED' && order.isOperatorViewer ? (
                <>
                    <Button variant="contained" sx={{ my: 2 }} onClick={() => setIsDialogOpen(true)}>
                        Add Transaction
                    </Button>
                    <Typography mt={3}>Order valid until 6pm closing market time</Typography>
                </>
            ) : null}

            <Dialog
                open={isDialogOpen}
                keepMounted
                onClose={closeDialog}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>Add transaction for order</DialogTitle>
                <form noValidate onSubmit={formik.handleSubmit}>
                    <>
                        <DialogContent sx={{ minWidth: 500 }}>
                            <DialogContentText id="alert-dialog-slide-description" component="div" textAlign="center">
                                <Typography variant="subtitle2" color="error">
                                    {formik.errors.submit}
                                </Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                                    <Typography>No of EUAs</Typography>
                                    <TextField
                                        error={!!(formik.touched.allowances && formik.errors.allowances)}
                                        fullWidth
                                        helperText={formik.touched.allowances && formik.errors.allowances}
                                        label="No of allowances"
                                        name="allowances"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        type="number"
                                        value={formik.values.allowances}
                                        sx={{ maxWidth: 200, ml: 4 }}
                                    />
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                                    <Typography>Price</Typography>
                                    <TextField
                                        error={!!(formik.touched.price && formik.errors.price)}
                                        fullWidth
                                        helperText={formik.touched.price && formik.errors.price}
                                        label="Price"
                                        name="price"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        type="number"
                                        value={formik.values.price}
                                        sx={{ maxWidth: 200, ml: 4 }}
                                    />
                                </Box>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                variant="contained"
                                type="submit"
                                sx={{ width: '10rem' }}
                                disabled={formik.isSubmitting}
                            >
                                {formik.isSubmitting ? (
                                    <CircularProgress color="inherit" size="1.5rem" />
                                ) : (
                                    'Add Transaction'
                                )}
                            </Button>
                            <Button variant="contained" color="error" onClick={closeDialog}>
                                Cancel
                            </Button>
                        </DialogActions>
                    </>
                </form>
            </Dialog>
        </>
    )
}
