/**
 * @generated SignedSource<<079a271ac812db98382936e7efe1b6f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClientCreateOrderQuery$variables = {};
export type ClientCreateOrderQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"OrderButton_viewer" | "PriceHistoryChart_viewer">;
  } | null;
};
export type ClientCreateOrderQuery = {
  response: ClientCreateOrderQuery$data;
  variables: ClientCreateOrderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientCreateOrderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrderButton_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PriceHistoryChart_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ClientCreateOrderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isViewer"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isMarketOpen",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PriceHistory",
            "kind": "LinkedField",
            "name": "priceHistory",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "created",
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6af6bb98999ad34a2511357cb55999f5",
    "id": null,
    "metadata": {},
    "name": "ClientCreateOrderQuery",
    "operationKind": "query",
    "text": "query ClientCreateOrderQuery {\n  viewer {\n    __typename\n    ...OrderButton_viewer\n    ...PriceHistoryChart_viewer\n    id\n  }\n}\n\nfragment OrderButton_viewer on Viewer {\n  __isViewer: __typename\n  isMarketOpen\n}\n\nfragment PriceHistoryChart_viewer on Viewer {\n  __isViewer: __typename\n  priceHistory {\n    value\n    created\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "6201b801b0177c4e75aa29873c6aa8e2";

export default node;
