/**
 * @generated SignedSource<<6af8bc00c2cec5a9be1561606fabe2a5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type IncomingOrdersTable_viewer$data = {
  readonly incomingOrders: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly allowancesOrdered: number;
        readonly id: string;
        readonly maxPrice: any;
      } | null;
    } | null>;
  };
  readonly " $fragmentType": "IncomingOrdersTable_viewer";
};
export type IncomingOrdersTable_viewer$key = {
  readonly " $data"?: IncomingOrdersTable_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"IncomingOrdersTable_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "forward",
        "path": [
          "incomingOrders"
        ]
      }
    ]
  },
  "name": "IncomingOrdersTable_viewer",
  "selections": [
    {
      "alias": "incomingOrders",
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "CREATED"
        },
        {
          "kind": "Literal",
          "name": "orderDir",
          "value": "DESC"
        },
        {
          "kind": "Literal",
          "name": "stateFilter",
          "value": "WAITING"
        }
      ],
      "concreteType": "OrderConnection",
      "kind": "LinkedField",
      "name": "__IncomingOrdersTable_incomingOrders_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Order",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "allowancesOrdered",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "maxPrice",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "__IncomingOrdersTable_incomingOrders_connection(orderBy:\"CREATED\",orderDir:\"DESC\",stateFilter:\"WAITING\")"
    }
  ],
  "type": "OperatorViewer",
  "abstractKey": null
};

(node as any).hash = "030f11d5a4a9eeb452af6d588e8cdd23";

export default node;
