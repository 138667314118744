import { CircularProgress, Drawer, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { Suspense } from 'react'
import NavLinks from './NavLinks.tsx'

export default function SideNav({ open, onClose }: { open: boolean; onClose: () => void }) {
    const theme = useTheme()
    const lgUp = useMediaQuery(theme.breakpoints.up('lg'))
    const content = (
        <Suspense fallback={<CircularProgress color="inherit" />}>
            <NavLinks />
        </Suspense>
    )

    if (lgUp) {
        return (
            <Drawer
                anchor="left"
                open
                PaperProps={{
                    sx: {
                        backgroundColor: 'neutral.800',
                        color: 'common.white',
                        width: 280,
                    },
                }}
                variant="permanent"
            >
                {content}
            </Drawer>
        )
    }

    return (
        <Drawer
            anchor="left"
            onClose={onClose}
            open={open}
            PaperProps={{
                sx: {
                    backgroundColor: 'neutral.800',
                    color: 'common.white',
                    width: 280,
                },
            }}
            sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
            variant="temporary"
        >
            {content}
        </Drawer>
    )
}
