import { graphql, usePaginationFragment, usePreloadedQuery, PreloadedQuery } from 'react-relay'
import { OrdersTable_operatorViewer$key } from '@/__generated__/OrdersTable_operatorViewer.graphql.ts'
import { useLoaderData, useNavigate } from 'react-router-dom'
import {
    OrdersOrder,
    OrdersTableOperatorQuery,
    OrderDirection,
} from '@/__generated__/OrdersTableOperatorQuery.graphql.ts'
import { OrdersTablePaginationOperatorQuery } from '@/__generated__/OrdersTablePaginationOperatorQuery.graphql.ts'
import DataGrid from '@/components/DataGrid'
import notEmpty from '@/types/notEmpty'
import useEffectSkipFirstRender from '@/hooks/useEffectSkipFirstRender'
import { fromGlobalId } from '@/utils/relay.ts'
import { format, parseISO } from 'date-fns'
import OrderStateChip from '@/components/OrderStateChip.tsx'
import formatEuro from '@/utils/formatEuro.ts'

export default function OrdersTable({
    searchTerm,
    orderBy,
    orderDir,
}: {
    searchTerm: string
    orderBy: OrdersOrder
    orderDir: OrderDirection
}) {
    const navigate = useNavigate()
    const { ordersQueryRef } = useLoaderData() as {
        ordersQueryRef: PreloadedQuery<OrdersTableOperatorQuery>
    }
    const { viewer } = usePreloadedQuery<OrdersTableOperatorQuery>(
        graphql`
            query OrdersTableOperatorQuery($searchTerm: String, $orderBy: OrdersOrder!, $orderDir: OrderDirection!) {
                viewer {
                    ...OrdersTable_operatorViewer
                        @arguments(searchTerm: $searchTerm, orderBy: $orderBy, orderDir: $orderDir)
                }
            }
        `,
        ordersQueryRef,
    )

    const { data, hasNext, loadNext, refetch } = usePaginationFragment<
        OrdersTablePaginationOperatorQuery,
        OrdersTable_operatorViewer$key
    >(
        graphql`
            fragment OrdersTable_operatorViewer on OperatorViewer
            @argumentDefinitions(
                count: { type: "Int", defaultValue: 50 }
                cursor: { type: "String" }
                searchTerm: { type: "String", defaultValue: "" }
                orderBy: { type: "OrdersOrder!" }
                orderDir: { type: "OrderDirection!" }
            )
            @refetchable(queryName: "OrdersTablePaginationOperatorQuery") {
                orders(first: $count, after: $cursor, searchTerm: $searchTerm, orderBy: $orderBy, orderDir: $orderDir)
                    @connection(key: "OperatorViewer_orders") {
                    edges {
                        node {
                            id
                            created
                            allowancesOrdered
                            maxPrice
                            allowancesFilled
                            totalPrice
                            customerCompany {
                                registeredCompanyName
                            }
                            providerCompany {
                                registeredCompanyName
                            }
                            operator {
                                firstName
                                lastName
                            }
                            ...OrderStateChip_order
                        }
                    }
                }
            }
        `,
        viewer,
    )

    useEffectSkipFirstRender(() => {
        refetch({ searchTerm, orderBy, orderDir }, { fetchPolicy: 'store-and-network' })
    }, [searchTerm, orderBy, orderDir, refetch])

    const foundOrders = data?.orders.edges?.map((orderEdge) => orderEdge?.node).filter(notEmpty) || []
    const gridStatusMessage = !data?.orders.edges.length ? (!data ? 'There was an error' : 'There are no orders') : ''

    return (
        <DataGrid
            scroller
            scrollerHasMore={hasNext}
            scrollerLoad={() => loadNext(50)}
            data={foundOrders}
            customMessage={gridStatusMessage}
            rowClick={(order) => navigate(`./${fromGlobalId(order.id).id}`)}
            columns={[
                {
                    title: 'Date',
                    key: 'created',
                    render: (order) => format(parseISO(order.created as string), 'd MMM yyyy, HH:mm'),
                },
                { title: 'ID', key: 'id', render: (item) => fromGlobalId(item.id).id },
                {
                    title: 'Customer',
                    key: 'customer',
                    render: (order) =>
                        order.customerCompany?.registeredCompanyName || order.providerCompany.registeredCompanyName,
                },
                {
                    title: 'Operator',
                    key: 'operator',
                    render: (order) =>
                        order.operator ? `${order.operator.firstName} ${order.operator.lastName}` : '-',
                },
                {
                    title: 'Status',
                    key: 'orderStatus',
                    render: (order) => <OrderStateChip order={order} />,
                },
                { title: 'Number of EUA', key: 'allowancesOrdered' },
                {
                    title: 'Medium Price',
                    key: 'mediumPrice',
                    render: (order) =>
                        order.allowancesFilled && order.totalPrice
                            ? formatEuro(order.totalPrice / order.allowancesFilled)
                            : '-',
                },
                {
                    title: 'Total Price',
                    key: 'totalPrice',
                    render: (order) => (order.totalPrice ? formatEuro(order.totalPrice) : '-'),
                },
            ]}
        />
    )
}
