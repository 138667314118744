/**
 * @generated SignedSource<<dd3de17abb955a500e71c9d4693bf4fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OrderState = "ACCEPTED" | "COMPLETED" | "REJECTED" | "WAITING";
import { FragmentRefs } from "relay-runtime";
export type OperatorOrderDetails_order$data = {
  readonly allowancesFilled: number | null;
  readonly allowancesOrdered: number;
  readonly created: any;
  readonly customerCompany: {
    readonly registeredCompanyName: string;
  } | null;
  readonly id: string;
  readonly maxPrice: any;
  readonly providerCompany: {
    readonly registeredCompanyName: string;
  };
  readonly state: OrderState;
  readonly " $fragmentSpreads": FragmentRefs<"OperatorTransactionsSection_order" | "OperatorTransferSection_order">;
  readonly " $fragmentType": "OperatorOrderDetails_order";
};
export type OperatorOrderDetails_order$key = {
  readonly " $data"?: OperatorOrderDetails_order$data;
  readonly " $fragmentSpreads": FragmentRefs<"OperatorOrderDetails_order">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "registeredCompanyName",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OperatorOrderDetails_order",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowancesOrdered",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowancesFilled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomerCompany",
      "kind": "LinkedField",
      "name": "customerCompany",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProviderCompany",
      "kind": "LinkedField",
      "name": "providerCompany",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OperatorTransactionsSection_order"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OperatorTransferSection_order"
    }
  ],
  "type": "Order",
  "abstractKey": null
};
})();

(node as any).hash = "0f4e2b6c477a738eb6fe4a518734b33f";

export default node;
