import { Box, Paper, Skeleton, Typography } from '@mui/material'
import { graphql, PreloadedQuery, usePreloadedQuery } from 'react-relay'
import { useLoaderData } from 'react-router-dom'
import { Suspense } from 'react'
import { PriceCardQuery } from '@/__generated__/PriceCardQuery.graphql.ts'
import formatEuro from '@/utils/formatEuro'

function PriceCard() {
    const { priceQueryRef } = useLoaderData() as { priceQueryRef: PreloadedQuery<PriceCardQuery> }
    const { viewer } = usePreloadedQuery<PriceCardQuery>(
        graphql`
            query PriceCardQuery {
                viewer {
                    euaPrice
                    userType
                    ... on CustomerViewer {
                        customerCompany {
                            euaToSurrender
                            euaPurchased
                        }
                    }
                    ... on ProviderViewer {
                        providerCompany {
                            euaToSurrender
                            euaPurchased
                        }
                    }
                }
            }
        `,
        priceQueryRef,
    )

    if (!viewer) {
        return null
    }

    const showBalance =
        viewer.userType === 'Provider' ||
        (viewer.userType === 'Customer' &&
            (viewer.customerCompany?.euaToSurrender || viewer.customerCompany?.euaPurchased))

    const euaBalance =
        viewer.userType === 'Provider'
            ? (viewer.providerCompany?.euaPurchased || 0) - (viewer.providerCompany?.euaToSurrender || 0)
            : viewer.userType === 'Customer'
            ? (viewer.customerCompany?.euaPurchased || 0) - (viewer.customerCompany?.euaToSurrender || 0)
            : 0

    return (
        <Paper
            variant="outlined"
            sx={{
                borderRadius: 'sm',
                p: 2,
                my: 2,
                display: 'flex',
            }}
        >
            <Box sx={{ marginRight: 3, display: 'flex', alignItems: 'center' }}>
                <Typography variant="h6" sx={{ mr: 1 }}>
                    Live EUA price:
                </Typography>
                <Suspense fallback={<Skeleton height={20} width={200} />}>
                    <Typography variant="h6" color="primary">
                        {formatEuro(viewer.euaPrice) || '-'}
                    </Typography>
                </Suspense>
                <Suspense fallback={null}>
                    {showBalance ? (
                        <>
                            <Typography variant="h6" sx={{ ml: 3, mr: 1 }}>
                                EUA balance:
                            </Typography>
                            <Typography variant="h6" color="primary">
                                {euaBalance}
                            </Typography>
                        </>
                    ) : null}
                </Suspense>
            </Box>
        </Paper>
    )
}

export default function PriceCardWithLoading() {
    return (
        <Suspense
            fallback={
                <Paper
                    variant="outlined"
                    sx={{
                        borderRadius: 'sm',
                        p: 2,
                        my: 2,
                        display: 'flex',
                    }}
                >
                    <Box sx={{ marginRight: 3, display: 'flex', alignItems: 'center' }}>
                        <Typography variant="h6" sx={{ mr: 1 }}>
                            Live EUA price:
                        </Typography>
                        <Skeleton height={20} width={200} />
                    </Box>
                </Paper>
            }
        >
            <PriceCard />
        </Suspense>
    )
}
