export const LOGIN_PATH = '/login'
export const LOGOUT_PATH = '/logout/'
export const GRAPHQL_PATH = '/graphql/'
export const ADMIN_PATH = '/admin/IR3krod2dBoZ/'

export const CUSTOMERS_PATH = '/customers'
export const TRIPS_PATH = '/trips'
export const DASHBOARD_PATH = '/dashboard'
export const ORDERS_PATH = '/orders'
export const ORDERS_DETAILS_PATH = '/orders/:id'
export const ORDERS_CREATE_PATH = '/orders/create'
export const SHIPS_PATH = '/ships'

export const GENERIC_ERROR_MESSAGE = 'Something went wrong. Please try again.'
