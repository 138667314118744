/**
 * @generated SignedSource<<3ce5a4e2412560715f4c6ee82ac3ef9d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrderDirection = "ASC" | "DESC";
export type OrdersOrder = "CREATED";
export type OrdersTableOperatorQuery$variables = {
  orderBy: OrdersOrder;
  orderDir: OrderDirection;
  searchTerm?: string | null;
};
export type OrdersTableOperatorQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"OrdersTable_operatorViewer">;
  } | null;
};
export type OrdersTableOperatorQuery = {
  response: OrdersTableOperatorQuery$data;
  variables: OrdersTableOperatorQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderDir"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchTerm"
},
v3 = {
  "kind": "Variable",
  "name": "orderBy",
  "variableName": "orderBy"
},
v4 = {
  "kind": "Variable",
  "name": "orderDir",
  "variableName": "orderDir"
},
v5 = {
  "kind": "Variable",
  "name": "searchTerm",
  "variableName": "searchTerm"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 50
  },
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/)
],
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "registeredCompanyName",
    "storageKey": null
  },
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrdersTableOperatorQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "OrdersTable_operatorViewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "OrdersTableOperatorQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "OrderConnection",
                "kind": "LinkedField",
                "name": "orders",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrderEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Order",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "created",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "allowancesOrdered",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "maxPrice",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "allowancesFilled",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "totalPrice",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CustomerCompany",
                            "kind": "LinkedField",
                            "name": "customerCompany",
                            "plural": false,
                            "selections": (v9/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProviderCompany",
                            "kind": "LinkedField",
                            "name": "providerCompany",
                            "plural": false,
                            "selections": (v9/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Operator",
                            "kind": "LinkedField",
                            "name": "operator",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "firstName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "lastName",
                                "storageKey": null
                              },
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "state",
                            "storageKey": null
                          },
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v8/*: any*/),
                "filters": [
                  "searchTerm",
                  "orderBy",
                  "orderDir"
                ],
                "handle": "connection",
                "key": "OperatorViewer_orders",
                "kind": "LinkedHandle",
                "name": "orders"
              }
            ],
            "type": "OperatorViewer",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d49b9754f4118d4e64a957a5899bfedc",
    "id": null,
    "metadata": {},
    "name": "OrdersTableOperatorQuery",
    "operationKind": "query",
    "text": "query OrdersTableOperatorQuery(\n  $searchTerm: String\n  $orderBy: OrdersOrder!\n  $orderDir: OrderDirection!\n) {\n  viewer {\n    __typename\n    ...OrdersTable_operatorViewer_9DenP\n    id\n  }\n}\n\nfragment OrderStateChip_order on Order {\n  state\n}\n\nfragment OrdersTable_operatorViewer_9DenP on OperatorViewer {\n  orders(first: 50, searchTerm: $searchTerm, orderBy: $orderBy, orderDir: $orderDir) {\n    edges {\n      node {\n        id\n        created\n        allowancesOrdered\n        maxPrice\n        allowancesFilled\n        totalPrice\n        customerCompany {\n          registeredCompanyName\n          id\n        }\n        providerCompany {\n          registeredCompanyName\n          id\n        }\n        operator {\n          firstName\n          lastName\n          id\n        }\n        ...OrderStateChip_order\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "65f60bd139f02749b0206787b0641d13";

export default node;
