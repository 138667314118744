import { Box, Card, MenuItem, Stack, TextField, Typography } from '@mui/material'
import { Suspense, useState } from 'react'
import SearchBox from '@/components/SearchBox.tsx'
import { CompaniesOrder, OrderDirection } from '@/__generated__/CustomersTableProviderQuery.graphql.ts'
import getKeys from '@/types/getKeys'
import CustomersTable from '@/pages/provider/customers/CustomersTable'
import LoadingDataGrid from '@/components/loading/LoadingDataGrid'
import PriceCard from '@/components/PriceCard'

type OrderOption = `${CompaniesOrder}_${OrderDirection}`

const ORDER_OPTIONS: { [key in OrderOption]: string } = {
    NAME_ASC: 'Name (ascending)',
    NAME_DESC: 'Name (descending)',
    CREATED_ASC: 'Created (oldest)',
    CREATED_DESC: 'Created (newest)',
}

function splitOrderOption(str: OrderOption) {
    return str.split('_') as [CompaniesOrder, OrderDirection]
}

function Customers() {
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [orderOption, setOrderOption] = useState<OrderOption>('NAME_ASC')

    return (
        <Box>
            <PriceCard />
            <Typography variant="h3" sx={{ my: 4 }}>
                Customers
            </Typography>
            <Card sx={{ mb: 3 }}>
                <Stack alignItems="center" direction="row" flexWrap="wrap" gap={2} sx={{ p: 3 }}>
                    <SearchBox value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} sx={{ flex: 1 }} />
                    <TextField
                        label="Sort By"
                        name="sort"
                        select
                        value={orderOption}
                        sx={{ width: 150 }}
                        onChange={(e) => setOrderOption(e.target.value as OrderOption)}
                    >
                        {getKeys(ORDER_OPTIONS).map((key) => (
                            <MenuItem key={key} value={key}>
                                {ORDER_OPTIONS[key]}
                            </MenuItem>
                        ))}
                    </TextField>
                </Stack>
                <Suspense fallback={<LoadingDataGrid />}>
                    <CustomersTable
                        searchTerm={searchTerm}
                        orderBy={splitOrderOption(orderOption)[0]}
                        orderDir={splitOrderOption(orderOption)[1]}
                    />
                </Suspense>
            </Card>
        </Box>
    )
}

export default Customers
