import { useCallback, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Outlet, useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { Box, Container, IconButton, Link, SvgIcon, Tooltip } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'

import { LOGOUT_PATH } from '@/config.ts'
import SideNav from './SideNav'

const SIDE_NAV_WIDTH = 280

const LayoutRoot = styled('div')(({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
    [theme.breakpoints.up('lg')]: {
        paddingLeft: SIDE_NAV_WIDTH,
    },
}))

export default function Layout() {
    const location = useLocation()
    const [openNav, setOpenNav] = useState(false)

    const handleLocationChange = useCallback(() => {
        if (openNav) {
            setOpenNav(false)
        }
    }, [openNav])

    useEffect(() => {
        handleLocationChange()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    return (
        <Box>
            <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
                <SideNav onClose={() => setOpenNav(false)} open={openNav} />
            </Sentry.ErrorBoundary>
            <Tooltip title="Log Out">
                <Link
                    href={LOGOUT_PATH}
                    sx={{
                        position: 'absolute',
                        top: 10,
                        right: 10,
                        color: 'neutral.400',
                    }}
                >
                    <SvgIcon fontSize="small">
                        <ExitToAppIcon />
                    </SvgIcon>
                </Link>
            </Tooltip>
            <LayoutRoot>
                <Container maxWidth="xl">
                    <Box sx={{ py: 3, px: 5 }}>
                        <IconButton
                            onClick={() => setOpenNav(true)}
                            sx={{
                                position: 'absolute',
                                top: 5,
                                left: 5,
                            }}
                        >
                            <SvgIcon fontSize="small">
                                <MenuIcon />
                            </SvgIcon>
                        </IconButton>

                        <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
                            <Outlet />
                        </Sentry.ErrorBoundary>
                    </Box>
                </Container>
            </LayoutRoot>
        </Box>
    )
}
