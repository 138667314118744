import { OrderButton_viewer$key } from '@/__generated__/OrderButton_viewer.graphql'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { graphql, useFragment } from 'react-relay'

interface IProps {
    viewer: OrderButton_viewer$key
    isSubmitting: boolean
    isDisabled: boolean
    onClick: () => void
}

export default function OrderButton(props: IProps) {
    const viewer = useFragment<OrderButton_viewer$key>(
        graphql`
            fragment OrderButton_viewer on Viewer {
                isMarketOpen
            }
        `,
        props.viewer,
    )

    return (
        <>
            <Button
                sx={{ mt: 3, width: 150, height: 50 }}
                variant="contained"
                disabled={!viewer.isMarketOpen || props.isDisabled}
                onClick={props.onClick}
            >
                {props.isSubmitting ? <CircularProgress color="inherit" size="1.5rem" /> : 'Send Order'}
            </Button>
            <Box sx={{ textAlign: 'center', mt: 2 }}>
                {viewer.isMarketOpen ? (
                    <>
                        <Typography>Market is currently open and will stay open till 6 PM CET</Typography>
                        <Typography>Your order is valid till the end of the day</Typography>
                    </>
                ) : (
                    <Typography color="error">Market is currently closed</Typography>
                )}
            </Box>
        </>
    )
}
