import LargePriceCard from '@/components/LargePriceCard.tsx'
import {
    Box,
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Skeleton,
    SvgIcon,
    TextField,
    Typography,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { object, number } from 'yup'
import { useFormik } from 'formik'
import formatEuro from '@/utils/formatEuro.ts'
import React, { Suspense, useState } from 'react'
import { GENERIC_ERROR_MESSAGE, ORDERS_PATH } from '@/config.ts'
import { graphql, PreloadedQuery, usePreloadedQuery } from 'react-relay'
import { useMutation } from 'react-relay'
import { ClientCreateOrderMutation } from '@/__generated__/ClientCreateOrderMutation.graphql.ts'
import { fromGlobalId } from '@/utils/relay.ts'
import '@/utils/initCharts'
import PriceHistoryChart from '@/components/PriceHistoryChart'
import { ClientCreateOrderQuery } from '@/__generated__/ClientCreateOrderQuery.graphql'
import OrderButton from './OrderButton'

export default function ClientCreateOrder() {
    const { createOrderQueryRef } = useLoaderData() as { createOrderQueryRef: PreloadedQuery<ClientCreateOrderQuery> }
    const { viewer } = usePreloadedQuery<ClientCreateOrderQuery>(
        graphql`
            query ClientCreateOrderQuery {
                viewer {
                    ...OrderButton_viewer
                    ...PriceHistoryChart_viewer
                }
            }
        `,
        createOrderQueryRef,
    )

    const [createOrder] = useMutation<ClientCreateOrderMutation>(graphql`
        mutation ClientCreateOrderMutation($euaOrdered: Int!, $maxBuyPrice: Decimal!) {
            createOrder(input: { euaOrdered: $euaOrdered, maxBuyPrice: $maxBuyPrice }) {
                order {
                    id
                }
            }
        }
    `)

    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            allowancesOrdered: 0,
            maxPrice: 0,
            submit: null,
        },
        validationSchema: object({
            allowancesOrdered: number()
                .required('EUA to order is required')
                .positive('The value must be a positive number')
                .integer('The value must be an integer'),
            maxPrice: number().required('Max price is required').positive('The value must be a positive number'),
        }),
        onSubmit: (values, helpers) => {
            createOrder({
                variables: {
                    euaOrdered: values.allowancesOrdered,
                    maxBuyPrice: values.maxPrice,
                },
                onCompleted: (data, errors) => {
                    helpers.setSubmitting(false)
                    if (errors || !data.createOrder?.order.id) {
                        helpers.setErrors({ submit: GENERIC_ERROR_MESSAGE })
                    } else {
                        navigate(`/orders/${fromGlobalId(data.createOrder.order.id).id}`)
                    }
                },
                onError: () => {
                    helpers.setSubmitting(false)
                    helpers.setErrors({ submit: GENERIC_ERROR_MESSAGE })
                },
            })
        },
    })

    const [isConfirmOpen, setIsConfirmOpen] = useState(false)
    const handleCloseConfirm = () => setIsConfirmOpen(false)
    const handleConfirm = () => {
        handleCloseConfirm()
        void formik.submitForm()
    }

    if (!viewer) {
        return null
    }

    return (
        <Box>
            <LargePriceCard />
            <Card sx={{ p: 3 }}>
                <Box sx={{ maxWidth: '900px', margin: 'auto' }}>
                    <IconButton onClick={() => navigate(ORDERS_PATH)}>
                        <SvgIcon fontSize="small">
                            <ArrowBackIcon />
                        </SvgIcon>
                    </IconButton>
                    <Box sx={{ maxHeight: 200, display: 'flex', justifyContent: 'center' }}>
                        <Suspense
                            fallback={
                                <Skeleton
                                    sx={{ bgcolor: 'neutral.100', borderRadius: 1 }}
                                    variant="rectangular"
                                    width={300}
                                    height={150}
                                />
                            }
                        >
                            <PriceHistoryChart viewer={viewer} />
                        </Suspense>
                    </Box>
                    <form noValidate onSubmit={formik.handleSubmit}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                            <div>
                                <Typography variant="h4">EUA to order</Typography>
                                <Typography>Number of allowances to buy through this order</Typography>
                            </div>
                            <TextField
                                error={!!(formik.touched.allowancesOrdered && formik.errors.allowancesOrdered)}
                                fullWidth
                                helperText={formik.touched.allowancesOrdered && formik.errors.allowancesOrdered}
                                label="EUA to order"
                                name="allowancesOrdered"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                type="number"
                                value={formik.values.allowancesOrdered}
                                sx={{ maxWidth: 200, ml: 4 }}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                            <div>
                                <Typography variant="h4">Max buying price</Typography>
                                <Typography>
                                    We will fulfil your order buying allowances under the price you set here. Your order
                                    can be only partially filled if the price on the market goes higher than your max
                                    buying price
                                </Typography>
                            </div>
                            <TextField
                                error={!!(formik.touched.maxPrice && formik.errors.maxPrice)}
                                fullWidth
                                helperText={formik.touched.maxPrice && formik.errors.maxPrice}
                                label="Max price"
                                name="maxPrice"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                type="number"
                                value={formik.values.maxPrice}
                                sx={{ maxWidth: 200, ml: 4 }}
                            />
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                            <Typography variant="h4">Max order value</Typography>
                            <Typography variant="h4">
                                {formatEuro(formik.values.allowancesOrdered * formik.values.maxPrice)}
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                            }}
                        >
                            {formik.errors.submit && (
                                <Typography color="error" sx={{ mt: 3 }} variant="body2">
                                    {formik.errors.submit as React.ReactNode}
                                </Typography>
                            )}

                            <Suspense
                                fallback={
                                    <>
                                        <Button sx={{ mt: 3, width: 150, height: 50 }} variant="contained" disabled>
                                            Send Order
                                        </Button>
                                        <Skeleton width={200} />
                                    </>
                                }
                            >
                                <OrderButton
                                    viewer={viewer}
                                    isDisabled={
                                        !formik.touched.maxPrice ||
                                        !formik.touched.allowancesOrdered ||
                                        !formik.isValid ||
                                        formik.isSubmitting
                                    }
                                    isSubmitting={formik.isSubmitting}
                                    onClick={() => setIsConfirmOpen(true)}
                                />
                            </Suspense>
                        </Box>

                        <Dialog
                            open={isConfirmOpen}
                            keepMounted
                            onClose={handleCloseConfirm}
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle></DialogTitle>
                            <DialogContent>
                                <DialogContentText
                                    id="alert-dialog-slide-description"
                                    component="div"
                                    textAlign="center"
                                >
                                    <Typography variant="h6">
                                        We are going to buy{' '}
                                        <Typography color="primary" component="span" variant="h6">
                                            {formik.values.allowancesOrdered}
                                        </Typography>{' '}
                                        EUAs at a max price of{' '}
                                        <Typography color="primary" component="span" variant="h6">
                                            {formatEuro(formik.values.maxPrice)}
                                        </Typography>{' '}
                                        which means the order can cost up to{' '}
                                        <Typography color="primary" component="span" variant="h6">
                                            {formatEuro(formik.values.allowancesOrdered * formik.values.maxPrice)}
                                        </Typography>
                                        .
                                    </Typography>
                                    <Typography variant="h6" sx={{ mt: 2 }}>
                                        Are you sure you want to place this order?
                                    </Typography>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Button variant="contained" color="error" onClick={handleCloseConfirm}>
                                    No
                                </Button>
                                <Button variant="contained" onClick={handleConfirm}>
                                    Yes
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </form>
                </Box>
            </Card>
        </Box>
    )
}
