import { RelayEnvironmentProvider } from 'react-relay'
import RelayEnvironment from '@/RelayEnvironment'
import React from 'react'
import ReactDOM from 'react-dom/client'
import '@fontsource-variable/plus-jakarta-sans'

import { MuiProvider } from '@/theme/index'
import * as Sentry from '@sentry/react'
import { createRoutesFromChildren, matchRoutes, RouterProvider, useLocation, useNavigationType } from 'react-router-dom'
import router from '@/router'

const constants = window.aither.constants

if (constants.VITE_SENTRY_DSN) {
    Sentry.init({
        dsn: constants.VITE_SENTRY_DSN,
        release: constants.HEROKU_SLUG_COMMIT,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                ),
            }),
        ],
    })
}

ReactDOM.createRoot(document.getElementById('root')!).render(
    <RelayEnvironmentProvider environment={RelayEnvironment}>
        <React.StrictMode>
            <MuiProvider>
                <Sentry.ErrorBoundary fallback={<p>Oops, something went wrong</p>}>
                    <RouterProvider router={router} />
                </Sentry.ErrorBoundary>
            </MuiProvider>
        </React.StrictMode>
    </RelayEnvironmentProvider>,
)
