import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import LoadingDataGridRow from '@/components/loading/LoadingDataGridRow.tsx'

export default function LoadingDataGrid() {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>&nbsp;</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <LoadingDataGridRow noOfCols={1} />
                <LoadingDataGridRow noOfCols={1} />
                <LoadingDataGridRow noOfCols={1} />
            </TableBody>
        </Table>
    )
}
