import { graphql, usePaginationFragment, usePreloadedQuery, PreloadedQuery } from 'react-relay'
import { ShipsTable_providerViewer$key } from '@/__generated__/ShipsTable_providerViewer.graphql.ts'
import { useLoaderData } from 'react-router-dom'
import { ShipsTableProviderQuery } from '@/__generated__/ShipsTableProviderQuery.graphql.ts'
import { ShipsTablePaginationProviderQuery } from '@/__generated__/ShipsTablePaginationProviderQuery.graphql.ts'
import DataGrid from '@/components/DataGrid'
import notEmpty from '@/types/notEmpty'
import useEffectSkipFirstRender from '@/hooks/useEffectSkipFirstRender'
import { fromGlobalId } from '@/utils/relay.ts'

export default function ShipsTable({ searchTerm }: { searchTerm: string }) {
    const { shipsQueryRef } = useLoaderData() as { shipsQueryRef: PreloadedQuery<ShipsTableProviderQuery> }
    const { viewer } = usePreloadedQuery<ShipsTableProviderQuery>(
        graphql`
            query ShipsTableProviderQuery($searchTerm: String) {
                viewer {
                    ... on ProviderViewer {
                        ...ShipsTable_providerViewer @arguments(searchTerm: $searchTerm)
                    }
                }
            }
        `,
        shipsQueryRef,
    )

    const {
        data: providerViewer,
        hasNext,
        loadNext,
        refetch,
    } = usePaginationFragment<ShipsTablePaginationProviderQuery, ShipsTable_providerViewer$key>(
        graphql`
            fragment ShipsTable_providerViewer on ProviderViewer
            @argumentDefinitions(
                count: { type: "Int", defaultValue: 50 }
                cursor: { type: "String" }
                searchTerm: { type: "String", defaultValue: "" }
            )
            @refetchable(queryName: "ShipsTablePaginationProviderQuery") {
                ships(first: $count, after: $cursor, searchTerm: $searchTerm)
                    @connection(key: "ProviderCompany_ships") {
                    edges {
                        node {
                            id
                            imo
                            tripsCount
                        }
                    }
                }
            }
        `,
        viewer,
    )

    useEffectSkipFirstRender(() => {
        refetch({ searchTerm }, { fetchPolicy: 'store-and-network' })
    }, [searchTerm, refetch])
    const foundShips = providerViewer?.ships.edges.map((shipEdge) => shipEdge?.node).filter(notEmpty) || []

    const gridStatusMessage = !providerViewer?.ships.edges.length
        ? !providerViewer
            ? 'There was an error'
            : searchTerm
            ? 'There are no ships matching your filters'
            : 'There are no ships'
        : ''
    return (
        <DataGrid
            scroller
            scrollerHasMore={hasNext}
            scrollerLoad={() => loadNext(50)}
            data={foundShips}
            customMessage={gridStatusMessage}
            columns={[
                { title: 'Ship IMO', key: 'imo' },
                { title: 'Ship ID', key: 'id', render: (item) => fromGlobalId(item.id).id },
                { title: 'No of trips', key: 'tripsCount' },
            ]}
        />
    )
}
