import { ThemeProvider, createTheme } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { createPalette } from './colors'
import { createShadows } from './shadows'
import { createTypography } from './typography'
import { createComponents } from './components'

export function MuiProvider({ children }: { children: React.ReactNode }) {
    const palette = createPalette()
    const components = createComponents({ palette })
    const shadows = createShadows()
    const typography = createTypography()

    const theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1440,
            },
        },
        components,
        palette,
        shadows,
        shape: {
            borderRadius: 8,
        },
        typography,
    })
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    )
}
