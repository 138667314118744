/**
 * @generated SignedSource<<458437635f87cb1a4cf1a8a0f57418cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ClientCreateOrderMutation$variables = {
  euaOrdered: number;
  maxBuyPrice: any;
};
export type ClientCreateOrderMutation$data = {
  readonly createOrder: {
    readonly order: {
      readonly id: string;
    };
  } | null;
};
export type ClientCreateOrderMutation = {
  response: ClientCreateOrderMutation$data;
  variables: ClientCreateOrderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "euaOrdered"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "maxBuyPrice"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "euaOrdered",
            "variableName": "euaOrdered"
          },
          {
            "kind": "Variable",
            "name": "maxBuyPrice",
            "variableName": "maxBuyPrice"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "CreateOrderPayload",
    "kind": "LinkedField",
    "name": "createOrder",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Order",
        "kind": "LinkedField",
        "name": "order",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClientCreateOrderMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClientCreateOrderMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4187b1a233e2e8a271d5f297432886a2",
    "id": null,
    "metadata": {},
    "name": "ClientCreateOrderMutation",
    "operationKind": "mutation",
    "text": "mutation ClientCreateOrderMutation(\n  $euaOrdered: Int!\n  $maxBuyPrice: Decimal!\n) {\n  createOrder(input: {euaOrdered: $euaOrdered, maxBuyPrice: $maxBuyPrice}) {\n    order {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "48777dabeb083e9fb89f0c922d262907";

export default node;
