/**
 * @generated SignedSource<<ab324b0a039dca4c02be33e301a5fddf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OrderState = "ACCEPTED" | "COMPLETED" | "REJECTED" | "WAITING";
import { FragmentRefs } from "relay-runtime";
export type OperatorTransactionsSection_order$data = {
  readonly allowancesFilled: number | null;
  readonly allowancesOrdered: number;
  readonly id: string;
  readonly isOperatorViewer: boolean | null;
  readonly state: OrderState;
  readonly transactions: ReadonlyArray<{
    readonly allowancesFilled: number;
    readonly id: string;
    readonly price: any;
  }>;
  readonly " $fragmentType": "OperatorTransactionsSection_order";
};
export type OperatorTransactionsSection_order$key = {
  readonly " $data"?: OperatorTransactionsSection_order$data;
  readonly " $fragmentSpreads": FragmentRefs<"OperatorTransactionsSection_order">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allowancesFilled",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OperatorTransactionsSection_order",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isOperatorViewer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowancesOrdered",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Transaction",
      "kind": "LinkedField",
      "name": "transactions",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "price",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};
})();

(node as any).hash = "ee256089d2a500362b680e69e91e26fc";

export default node;
