import LargePriceCard from '@/components/LargePriceCard'
import { Box, Card, Stack, Typography } from '@mui/material'
import IncomingOrdersTable from './IncomingOrdersTable'
import OpenOrdersTable from './OpenOrdersTable.tsx'
import LoadingDataGrid from '@/components/loading/LoadingDataGrid.tsx'
import { Suspense } from 'react'
import { useLoaderData } from 'react-router-dom'
import { graphql, PreloadedQuery, usePreloadedQuery } from 'react-relay'
import { DashboardOperatorQuery } from '@/__generated__/DashboardOperatorQuery.graphql.ts'

export default function Dashboard() {
    const { dashboardQueryRef } = useLoaderData() as {
        dashboardQueryRef: PreloadedQuery<DashboardOperatorQuery>
    }
    const { viewer } = usePreloadedQuery<DashboardOperatorQuery>(
        graphql`
            query DashboardOperatorQuery {
                viewer {
                    ...IncomingOrdersTable_viewer
                    ...OpenOrdersTable_viewer
                }
            }
        `,
        dashboardQueryRef,
    )

    if (!viewer) {
        return null
    }

    return (
        <Box>
            <LargePriceCard />
            <Stack>
                <Card sx={{ my: 2 }}>
                    <Typography variant="h5" sx={{ ml: 2, my: 2 }}>
                        Incoming Orders
                    </Typography>
                    <Suspense fallback={<LoadingDataGrid />}>
                        <IncomingOrdersTable viewer={viewer} />
                    </Suspense>
                </Card>
                <Card sx={{ my: 2 }}>
                    <Typography variant="h5" sx={{ ml: 2, my: 2 }}>
                        Open Orders
                    </Typography>
                    <Suspense fallback={<LoadingDataGrid />}>
                        <OpenOrdersTable viewer={viewer} />
                    </Suspense>
                </Card>
            </Stack>
        </Box>
    )
}
