import BusinessIcon from '@mui/icons-material/Business'
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import AirlineStopsIcon from '@mui/icons-material/AirlineStops'
import TodayIcon from '@mui/icons-material/Today'

export default function getIconComponent(name: string) {
    switch (name) {
        case 'BusinessIcon':
            return <BusinessIcon />
        case 'DirectionsBoatIcon':
            return <DirectionsBoatIcon />
        case 'AddShoppingCartIcon':
            return <AddShoppingCartIcon />
        case 'AirlineStopsIcon':
            return <AirlineStopsIcon />
        case 'TodayIcon':
            return <TodayIcon />
        default:
            return <BusinessIcon />
    }
}
