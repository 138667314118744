import { Box, ButtonBase, Stack, SvgIcon, Typography } from '@mui/material'
import { Link, useLoaderData, useLocation } from 'react-router-dom'

import getIconComponent from '@/components/getIconComponent.tsx'
import { graphql, PreloadedQuery, usePreloadedQuery } from 'react-relay'
import { NavLinksQuery } from '@/__generated__/NavLinksQuery.graphql'
import { CUSTOMERS_PATH, ORDERS_PATH, TRIPS_PATH, SHIPS_PATH, DASHBOARD_PATH } from '@/config.ts'

import aitherLogo from '/aither.png'
import vShipsLogo from '/v-ship_logo_green.svg'
import { UserType } from '@/__generated__/routerQuery.graphql.ts'

export const VIEWER_LINKS: Record<UserType, string[]> = {
    Admin: [],
    Operator: [DASHBOARD_PATH, CUSTOMERS_PATH, ORDERS_PATH],
    Provider: [CUSTOMERS_PATH, TRIPS_PATH, SHIPS_PATH, ORDERS_PATH],
    Customer: [TRIPS_PATH, ORDERS_PATH],
}

const NAV_LINKS: Record<string, { text: string; icon: string }> = {
    [CUSTOMERS_PATH]: { text: 'Customers', icon: 'BusinessIcon' },
    [TRIPS_PATH]: { text: 'Trips', icon: 'AirlineStopsIcon' },
    [ORDERS_PATH]: { text: 'Orders', icon: 'AddShoppingCartIcon' },
    [SHIPS_PATH]: { text: 'Ships', icon: 'DirectionsBoatIcon' },
    [DASHBOARD_PATH]: { text: 'Dashboard', icon: 'TodayIcon' },
}

export default function NavLinks() {
    const location = useLocation()

    const { sideNavQueryRef } = useLoaderData() as { sideNavQueryRef: PreloadedQuery<NavLinksQuery> }
    const { viewer } = usePreloadedQuery(
        graphql`
            query NavLinksQuery {
                viewer {
                    userType
                }
            }
        `,
        sideNavQueryRef,
    )

    if (!viewer) {
        return null
    }

    const userType = viewer?.userType
    const brand = userType === 'Customer' ? 'Ships' : 'Aither'

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
                <img
                    style={{ height: '1.75rem', marginRight: '10px' }}
                    src={userType === 'Customer' ? vShipsLogo : aitherLogo}
                    className="logo"
                    alt={`${brand} logo`}
                />
                <Typography variant="h1" color="common.white" sx={{ fontSize: '2rem', fontWeight: '500' }}>
                    {brand}
                </Typography>
            </Box>
            <Box
                component="nav"
                sx={{
                    flexGrow: 1,
                    px: 2,
                    py: 3,
                }}
            >
                <Stack
                    component="ul"
                    spacing={0.5}
                    sx={{
                        listStyle: 'none',
                        p: 0,
                        m: 0,
                    }}
                >
                    {(userType ? VIEWER_LINKS[userType] : []).map((route) => {
                        const { icon, text } = NAV_LINKS[route]
                        const path = route
                        const active = path ? location.pathname.includes(path) : false
                        return (
                            <li key={path}>
                                <Link to={path} style={{ textDecoration: 'none' }}>
                                    <ButtonBase
                                        sx={{
                                            alignItems: 'center',
                                            borderRadius: 1,
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            pl: '16px',
                                            pr: '16px',
                                            py: '6px',
                                            textAlign: 'left',
                                            width: '100%',
                                            color: 'neutral.400',
                                            ...(active && {
                                                backgroundColor: 'rgba(255, 255, 255, 0.04)',
                                                color: 'primary.contrastText',
                                            }),
                                            '&:hover': {
                                                backgroundColor: 'rgba(255, 255, 255, 0.04)',
                                            },
                                        }}
                                    >
                                        <Box
                                            component="span"
                                            sx={{
                                                alignItems: 'center',
                                                display: 'inline-flex',
                                                justifyContent: 'center',
                                                mr: 2,
                                            }}
                                        >
                                            <SvgIcon fontSize="small">{getIconComponent(icon)}</SvgIcon>
                                        </Box>
                                        <Box
                                            component="span"
                                            sx={{
                                                flexGrow: 1,
                                                fontFamily: (theme) => theme.typography.fontFamily,
                                                fontSize: 14,
                                                fontWeight: 600,
                                                lineHeight: '24px',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            {text}
                                        </Box>
                                    </ButtonBase>
                                </Link>
                            </li>
                        )
                    })}
                </Stack>
            </Box>
        </Box>
    )
}
