/**
 * @generated SignedSource<<fa6e2becdf86a800bb2a5c7148188af1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OperatorOrderDetailsRejectOrderMutation$variables = {
  orderId: string;
};
export type OperatorOrderDetailsRejectOrderMutation$data = {
  readonly rejectOrder: {
    readonly order: {
      readonly " $fragmentSpreads": FragmentRefs<"OperatorOrderDetails_order">;
    };
  } | null;
};
export type OperatorOrderDetailsRejectOrderMutation = {
  response: OperatorOrderDetailsRejectOrderMutation$data;
  variables: OperatorOrderDetailsRejectOrderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orderId"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "orderId",
        "variableName": "orderId"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allowancesFilled",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "registeredCompanyName",
    "storageKey": null
  },
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OperatorOrderDetailsRejectOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RejectOrderPayload",
        "kind": "LinkedField",
        "name": "rejectOrder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Order",
            "kind": "LinkedField",
            "name": "order",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "OperatorOrderDetails_order"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OperatorOrderDetailsRejectOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RejectOrderPayload",
        "kind": "LinkedField",
        "name": "rejectOrder",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Order",
            "kind": "LinkedField",
            "name": "order",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "state",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "allowancesOrdered",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "maxPrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "created",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomerCompany",
                "kind": "LinkedField",
                "name": "customerCompany",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProviderCompany",
                "kind": "LinkedField",
                "name": "providerCompany",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isOperatorViewer",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Transaction",
                "kind": "LinkedField",
                "name": "transactions",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "price",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Operator",
                "kind": "LinkedField",
                "name": "operator",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f7141663f219163d8142d4b9cf808aa7",
    "id": null,
    "metadata": {},
    "name": "OperatorOrderDetailsRejectOrderMutation",
    "operationKind": "mutation",
    "text": "mutation OperatorOrderDetailsRejectOrderMutation(\n  $orderId: ID!\n) {\n  rejectOrder(input: {orderId: $orderId}) {\n    order {\n      ...OperatorOrderDetails_order\n      id\n    }\n  }\n}\n\nfragment OperatorOrderDetails_order on Order {\n  id\n  state\n  allowancesOrdered\n  allowancesFilled\n  maxPrice\n  created\n  customerCompany {\n    registeredCompanyName\n    id\n  }\n  providerCompany {\n    registeredCompanyName\n    id\n  }\n  ...OperatorTransactionsSection_order\n  ...OperatorTransferSection_order\n}\n\nfragment OperatorTransactionsSection_order on Order {\n  id\n  state\n  isOperatorViewer\n  allowancesOrdered\n  allowancesFilled\n  transactions {\n    id\n    allowancesFilled\n    price\n  }\n}\n\nfragment OperatorTransferSection_order on Order {\n  id\n  state\n  isOperatorViewer\n  operator {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "5737b25a3f443b875c8ca7291beb2a8c";

export default node;
