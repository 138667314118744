import { graphql, useFragment } from 'react-relay'
import { Line } from 'react-chartjs-2'
import { PriceHistoryChart_viewer$key } from '@/__generated__/PriceHistoryChart_viewer.graphql.ts'
import { format, parseISO } from 'date-fns'

export default function PriceHistoryChart(props: { viewer: PriceHistoryChart_viewer$key }) {
    const viewer = useFragment<PriceHistoryChart_viewer$key>(
        graphql`
            fragment PriceHistoryChart_viewer on Viewer {
                priceHistory {
                    value
                    created
                }
            }
        `,
        props.viewer,
    )

    const labels = viewer.priceHistory.map((ph) => format(parseISO(ph.created), 'dd/MM')).reverse()
    const data = viewer.priceHistory.map((ph) => ph.value).reverse()

    return (
        <Line
            options={{
                animation: false,
                responsive: false,
                plugins: {
                    title: {
                        display: true,
                        text: 'EUA daily price trend',
                    },
                    legend: {
                        display: false,
                    },
                },
            }}
            data={{
                labels,
                datasets: [
                    {
                        label: 'price',
                        data,
                        borderColor: '#16B364',
                        backgroundColor: '#16B364',
                    },
                ],
            }}
        />
    )
}
