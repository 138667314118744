import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import InfiniteScroller from 'react-infinite-scroller'
import LoadingDataGridRow from './loading/LoadingDataGridRow'

type Column<T> =
    | {
          title: string
          key: keyof T
          render?: (item: T) => React.ReactNode
      }
    | {
          title: string
          key: string
          render: (item: T) => React.ReactNode
      }

export default function DataGrid<T extends { id: string }>({
    data,
    columns,
    rowClick,
    scroller = false,
    scrollerHasMore,
    scrollerLoad,
    customMessage,
}: {
    data: readonly T[]
    columns: Column<T>[]
    rowClick?: (item: T) => void
    scroller?: boolean
    scrollerHasMore?: boolean
    scrollerLoad?: () => unknown
    customMessage?: string
}) {
    const rows = data.map((item) => (
        <TableRow
            key={item.id}
            hover={!!rowClick}
            onClick={() => rowClick && rowClick(item)}
            sx={{
                cursor: rowClick ? 'pointer' : 'initial',
            }}
        >
            {columns.map(({ key, render }) => (
                <TableCell key={key as string}>{render ? render(item) : item[key as keyof T]}</TableCell>
            ))}
        </TableRow>
    ))
    return (
        <Table>
            <TableHead>
                <TableRow>
                    {columns.map((col) => (
                        <TableCell key={col.key as string}>{col.title}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            {customMessage ? (
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={columns.length}>{customMessage}</TableCell>
                    </TableRow>
                </TableBody>
            ) : scroller ? (
                <InfiniteScroller
                    loadMore={scrollerLoad!}
                    hasMore={scrollerHasMore}
                    element="tbody"
                    loader={<LoadingDataGridRow key="loading-row" noOfCols={columns.length} />}
                >
                    {rows}
                </InfiniteScroller>
            ) : (
                <TableBody>{rows}</TableBody>
            )}
        </Table>
    )
}
