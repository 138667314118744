/**
 * @generated SignedSource<<d73eec18cb07fd8fb2f0f89a7ed56532>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OrderState = "ACCEPTED" | "COMPLETED" | "REJECTED" | "WAITING";
import { FragmentRefs } from "relay-runtime";
export type OperatorTransferSection_order$data = {
  readonly id: string;
  readonly isOperatorViewer: boolean | null;
  readonly operator: {
    readonly id: string;
  } | null;
  readonly state: OrderState;
  readonly " $fragmentType": "OperatorTransferSection_order";
};
export type OperatorTransferSection_order$key = {
  readonly " $data"?: OperatorTransferSection_order$data;
  readonly " $fragmentSpreads": FragmentRefs<"OperatorTransferSection_order">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OperatorTransferSection_order",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isOperatorViewer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Operator",
      "kind": "LinkedField",
      "name": "operator",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};
})();

(node as any).hash = "53809d9befc8f9f6af3c2c036eb98810";

export default node;
