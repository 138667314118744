/**
 * @generated SignedSource<<5be42154d37e5d62f761fb4ff4cba8e4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PriceHistoryChart_viewer$data = {
  readonly priceHistory: ReadonlyArray<{
    readonly created: any;
    readonly value: any;
  }>;
  readonly " $fragmentType": "PriceHistoryChart_viewer";
};
export type PriceHistoryChart_viewer$key = {
  readonly " $data"?: PriceHistoryChart_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"PriceHistoryChart_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PriceHistoryChart_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PriceHistory",
      "kind": "LinkedField",
      "name": "priceHistory",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "created",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": "__isViewer"
};

(node as any).hash = "df87825691fec399de718c9a8e8e6d7d";

export default node;
