import { graphql, usePaginationFragment, usePreloadedQuery, PreloadedQuery } from 'react-relay'
import { OrdersTable_customerViewer$key } from '@/__generated__/OrdersTable_customerViewer.graphql.ts'
import { useLoaderData, useNavigate } from 'react-router-dom'
import {
    OrdersOrder,
    OrdersTableCustomerQuery,
    OrderDirection,
} from '@/__generated__/OrdersTableCustomerQuery.graphql.ts'
import { OrdersTablePaginationCustomerQuery } from '@/__generated__/OrdersTablePaginationCustomerQuery.graphql.ts'
import DataGrid from '@/components/DataGrid'
import notEmpty from '@/types/notEmpty'
import useEffectSkipFirstRender from '@/hooks/useEffectSkipFirstRender'
import { fromGlobalId } from '@/utils/relay.ts'
import OrderStateChip from '@/components/OrderStateChip.tsx'
import formatEuro from '@/utils/formatEuro.ts'

export default function OrdersTable({ orderBy, orderDir }: { orderBy: OrdersOrder; orderDir: OrderDirection }) {
    const navigate = useNavigate()
    const { ordersQueryRef } = useLoaderData() as {
        ordersQueryRef: PreloadedQuery<OrdersTableCustomerQuery>
    }
    const { viewer } = usePreloadedQuery<OrdersTableCustomerQuery>(
        graphql`
            query OrdersTableCustomerQuery($orderBy: OrdersOrder!, $orderDir: OrderDirection!) {
                viewer {
                    ...OrdersTable_customerViewer @arguments(orderBy: $orderBy, orderDir: $orderDir)
                }
            }
        `,
        ordersQueryRef,
    )

    const { data, hasNext, loadNext, refetch } = usePaginationFragment<
        OrdersTablePaginationCustomerQuery,
        OrdersTable_customerViewer$key
    >(
        graphql`
            fragment OrdersTable_customerViewer on CustomerViewer
            @argumentDefinitions(
                count: { type: "Int", defaultValue: 50 }
                cursor: { type: "String" }
                orderBy: { type: "OrdersOrder!" }
                orderDir: { type: "OrderDirection!" }
            )
            @refetchable(queryName: "OrdersTablePaginationCustomerQuery") {
                orders(first: $count, after: $cursor, orderBy: $orderBy, orderDir: $orderDir)
                    @connection(key: "CustomerViewer_orders") {
                    edges {
                        node {
                            id
                            allowancesOrdered
                            maxPrice
                            totalPrice
                            ...OrderStateChip_order
                        }
                    }
                }
            }
        `,
        viewer || null,
    )

    useEffectSkipFirstRender(() => {
        refetch({ orderBy, orderDir }, { fetchPolicy: 'store-and-network' })
    }, [orderBy, orderDir, refetch])

    const foundOrders = data?.orders.edges?.map((orderEdge) => orderEdge?.node).filter(notEmpty) || []
    const gridStatusMessage = !data?.orders.edges.length ? (!data ? 'There was an error' : 'There are no orders') : ''
    return (
        <DataGrid
            scroller
            scrollerHasMore={hasNext}
            scrollerLoad={() => loadNext(50)}
            data={foundOrders}
            customMessage={gridStatusMessage}
            rowClick={(order) => navigate(`./${fromGlobalId(order.id).id}`)}
            columns={[
                { title: 'Order ID', key: 'id', render: (item) => fromGlobalId(item.id).id },
                { title: 'EUA Ordered', key: 'allowancesOrdered' },
                { title: 'Max Buy Price', key: 'maxPrice', render: (order) => formatEuro(order.maxPrice) },
                {
                    title: 'Order Status',
                    key: 'orderStatus',
                    render: (order) => <OrderStateChip order={order} />,
                },
                {
                    title: 'Total Price',
                    key: 'totalPrice',
                    render: (order) => (order.totalPrice ? formatEuro(order.totalPrice) : '-'),
                },
            ]}
        />
    )
}
