import { Skeleton, TableCell, TableRow } from '@mui/material'

export default function LoadingDataGridRow({ noOfCols }: { noOfCols: number }) {
    return (
        <TableRow>
            <TableCell colSpan={noOfCols}>
                <Skeleton
                    sx={{ bgcolor: 'neutral.100', borderRadius: 1 }}
                    variant="rectangular"
                    width={'100%'}
                    height={21}
                />
            </TableCell>
        </TableRow>
    )
}
