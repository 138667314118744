import { createBrowserRouter, redirect, RouteObject } from 'react-router-dom'
import { fetchQuery, graphql, loadQuery } from 'react-relay'
import * as Sentry from '@sentry/react'
import { Link } from '@mui/material'

import NotFound from '@/pages/NotFound'
import Layout from '@/layout/index'
import RelayEnvironment from '@/RelayEnvironment'
import Auth from '@/layout/Auth'
import Login from '@/pages/auth/Login'
import Confirm from '@/pages/auth/Confirm'
import {
    CUSTOMERS_PATH,
    LOGIN_PATH,
    ORDERS_PATH,
    TRIPS_PATH,
    SHIPS_PATH,
    ADMIN_PATH,
    ORDERS_DETAILS_PATH,
    ORDERS_CREATE_PATH,
    DASHBOARD_PATH,
} from '@/config'

import { routerQuery, UserType } from '@/__generated__/routerQuery.graphql'
import NavLinksQuery, { NavLinksQuery as NavLinksQueryType } from '@/__generated__/NavLinksQuery.graphql'
import PriceCardQuery, { PriceCardQuery as PriceCardQueryType } from '@/__generated__/PriceCardQuery.graphql'
import LargePriceCardQuery, {
    LargePriceCardQuery as LargePriceCardQueryType,
} from '@/__generated__/LargePriceCardQuery.graphql'
import CustomersTableProviderQuery, {
    CustomersTableProviderQuery as CustomersTableProviderQueryType,
} from '@/__generated__/CustomersTableProviderQuery.graphql'
import CustomersTableOperatorQuery, {
    CustomersTableOperatorQuery as CustomersTableOperatorQueryType,
} from '@/__generated__/CustomersTableOperatorQuery.graphql'
import TripsTableProviderQuery, {
    TripsTableProviderQuery as TripsTableProviderQueryType,
} from '@/__generated__/TripsTableProviderQuery.graphql'
import ShipsTableProviderQuery, {
    ShipsTableProviderQuery as ShipsTableProviderQueryType,
} from '@/__generated__/ShipsTableProviderQuery.graphql'
import OrdersTableProviderQuery, {
    OrdersTableProviderQuery as OrdersTableProviderQueryType,
} from '@/__generated__/OrdersTableProviderQuery.graphql'
import OrdersTableOperatorQuery, {
    OrdersTableOperatorQuery as OrdersTableOperatorQueryType,
} from '@/__generated__/OrdersTableOperatorQuery.graphql'
import OrdersTableCustomerQuery, {
    OrdersTableCustomerQuery as OrdersTableCustomerQueryType,
} from '@/__generated__/OrdersTableCustomerQuery.graphql'
import TripsTableCustomerQuery, {
    TripsTableCustomerQuery as TripsTableCustomerQueryType,
} from '@/__generated__/TripsTableCustomerQuery.graphql'
import ClientOrderDetailsQuery, {
    ClientOrderDetailsQuery as ClientOrderDetailsQueryType,
} from '@/__generated__/ClientOrderDetailsQuery.graphql.ts'
import ClientCreateOrderQuery, {
    ClientCreateOrderQuery as ClientCreateOrderQueryType,
} from '@/__generated__/ClientCreateOrderQuery.graphql.ts'
import OperatorOrderDetailsQuery, {
    OperatorOrderDetailsQuery as OperatorOrderDetailsQueryType,
} from '@/__generated__/OperatorOrderDetailsQuery.graphql.ts'
import DashboardOperatorQuery, {
    DashboardOperatorQuery as DashboardOperatorQueryType,
} from '@/__generated__/DashboardOperatorQuery.graphql.ts'

import CustomerTrips from '@/pages/customer/trips'
import CustomerOrders from '@/pages/customer/orders'

import OperatorCustomers from '@/pages/operator/customers'
import OperatorOrders from '@/pages/operator/orders'
import OperatorOrderDetails from '@/pages/operator/orders/details/OperatorOrderDetails.tsx'
import OperatorDashboard from '@/pages/operator/dashboard/Dashboard.tsx'

import ProviderCustomers from '@/pages/provider/customers'
import ProviderOrders from '@/pages/provider/orders'
import ClientOrderDetails from '@/pages/shared/ClientOrderDetails.tsx'
import ClientCreateOrder from '@/pages/shared/ClientCreateOrder.tsx'
import ProviderShips from '@/pages/provider/ships'
import ProviderTrips from '@/pages/provider/trips'
import { toGlobalId } from '@/utils/relay.ts'

const result = await fetchQuery<routerQuery>(
    RelayEnvironment,
    graphql`
        query routerQuery {
            viewer {
                userType
            }
        }
    `,
    {},
    { fetchPolicy: 'store-or-network' },
).toPromise()

const userType = result?.viewer?.userType

const DEFAULT_ROUTE_FOR_USER_TYPE: {
    [key in UserType]: string
} = {
    Admin: ADMIN_PATH,
    Operator: DASHBOARD_PATH,
    Provider: CUSTOMERS_PATH,
    Customer: TRIPS_PATH,
}

const ORDER_CREATE_ROUTE: RouteObject = {
    path: ORDERS_CREATE_PATH,
    element: <ClientCreateOrder />,
    loader: () => ({
        createOrderQueryRef: loadQuery<ClientCreateOrderQueryType>(
            RelayEnvironment,
            ClientCreateOrderQuery,
            {},
            { fetchPolicy: 'store-and-network' },
        ),
        largePriceQueryRef: loadQuery<LargePriceCardQueryType>(
            RelayEnvironment,
            LargePriceCardQuery,
            {},
            { fetchPolicy: 'store-and-network' },
        ),
    }),
}
const ORDER_DETAILS_ROUTE: RouteObject = {
    path: ORDERS_DETAILS_PATH,
    element: <ClientOrderDetails />,
    loader: ({ params: { id } }) => ({
        orderDetailsQueryRef: loadQuery<ClientOrderDetailsQueryType>(
            RelayEnvironment,
            ClientOrderDetailsQuery,
            { id: toGlobalId('Order', id || '') },
            { fetchPolicy: 'store-and-network' },
        ),
    }),
}

const ROUTES_FOR_USER_TYPE: Record<UserType, RouteObject[]> = {
    Admin: [],
    Operator: [
        {
            path: CUSTOMERS_PATH,
            element: <OperatorCustomers />,
            loader: () => ({
                customersQueryRef: loadQuery<CustomersTableOperatorQueryType>(
                    RelayEnvironment,
                    CustomersTableOperatorQuery,
                    {
                        searchTerm: '',
                        orderBy: 'NAME',
                        orderDir: 'ASC',
                    },
                    { fetchPolicy: 'store-and-network' },
                ),
                priceQueryRef: loadQuery<PriceCardQueryType>(
                    RelayEnvironment,
                    PriceCardQuery,
                    {},
                    { fetchPolicy: 'store-and-network' },
                ),
            }),
        },
        {
            path: ORDERS_PATH,
            element: <OperatorOrders />,
            loader: () => ({
                ordersQueryRef: loadQuery<OrdersTableOperatorQueryType>(
                    RelayEnvironment,
                    OrdersTableOperatorQuery,
                    {
                        searchTerm: '',
                        orderBy: 'CREATED',
                        orderDir: 'DESC',
                    },
                    { fetchPolicy: 'store-and-network' },
                ),
            }),
        },
        {
            path: DASHBOARD_PATH,
            element: <OperatorDashboard />,
            loader: () => ({
                dashboardQueryRef: loadQuery<DashboardOperatorQueryType>(
                    RelayEnvironment,
                    DashboardOperatorQuery,
                    {},
                    { fetchPolicy: 'store-and-network' },
                ),
                largePriceQueryRef: loadQuery<LargePriceCardQueryType>(
                    RelayEnvironment,
                    LargePriceCardQuery,
                    {},
                    { fetchPolicy: 'store-and-network' },
                ),
            }),
        },
        {
            path: ORDERS_DETAILS_PATH,
            element: <OperatorOrderDetails />,
            loader: ({ params: { id } }) => ({
                orderDetailsQueryRef: loadQuery<OperatorOrderDetailsQueryType>(
                    RelayEnvironment,
                    OperatorOrderDetailsQuery,
                    { id: toGlobalId('Order', id || '') },
                    { fetchPolicy: 'store-and-network' },
                ),
            }),
        },
    ],
    Provider: [
        {
            path: CUSTOMERS_PATH,
            element: <ProviderCustomers />,
            loader: () => ({
                customersQueryRef: loadQuery<CustomersTableProviderQueryType>(
                    RelayEnvironment,
                    CustomersTableProviderQuery,
                    {
                        searchTerm: '',
                        orderBy: 'NAME',
                        orderDir: 'ASC',
                    },
                    { fetchPolicy: 'store-and-network' },
                ),
                priceQueryRef: loadQuery<PriceCardQueryType>(
                    RelayEnvironment,
                    PriceCardQuery,
                    {},
                    { fetchPolicy: 'store-and-network' },
                ),
            }),
        },
        {
            path: TRIPS_PATH,
            element: <ProviderTrips />,
            loader: () => ({
                tripsQueryRef: loadQuery<TripsTableProviderQueryType>(
                    RelayEnvironment,
                    TripsTableProviderQuery,
                    {
                        searchTerm: '',
                        isCustomerBuyer: 'ALL',
                    },
                    { fetchPolicy: 'store-and-network' },
                ),
                priceQueryRef: loadQuery<PriceCardQueryType>(
                    RelayEnvironment,
                    PriceCardQuery,
                    {},
                    { fetchPolicy: 'store-and-network' },
                ),
            }),
        },
        {
            path: SHIPS_PATH,
            element: <ProviderShips />,
            loader: () => ({
                shipsQueryRef: loadQuery<ShipsTableProviderQueryType>(
                    RelayEnvironment,
                    ShipsTableProviderQuery,
                    { searchTerm: '' },
                    { fetchPolicy: 'store-and-network' },
                ),
                priceQueryRef: loadQuery<PriceCardQueryType>(
                    RelayEnvironment,
                    PriceCardQuery,
                    {},
                    { fetchPolicy: 'store-and-network' },
                ),
            }),
        },
        {
            path: ORDERS_PATH,
            element: <ProviderOrders />,
            loader: () => ({
                ordersQueryRef: loadQuery<OrdersTableProviderQueryType>(
                    RelayEnvironment,
                    OrdersTableProviderQuery,
                    {
                        orderBy: 'CREATED',
                        orderDir: 'DESC',
                    },
                    { fetchPolicy: 'store-and-network' },
                ),
                largePriceQueryRef: loadQuery<LargePriceCardQueryType>(
                    RelayEnvironment,
                    LargePriceCardQuery,
                    {},
                    { fetchPolicy: 'store-and-network' },
                ),
            }),
        },
        ORDER_CREATE_ROUTE,
        ORDER_DETAILS_ROUTE,
    ],
    Customer: [
        {
            path: TRIPS_PATH,
            element: <CustomerTrips />,
            loader: () => ({
                tripsQueryRef: loadQuery<TripsTableCustomerQueryType>(
                    RelayEnvironment,
                    TripsTableCustomerQuery,
                    { searchTerm: '' },
                    { fetchPolicy: 'store-and-network' },
                ),
                priceQueryRef: loadQuery<PriceCardQueryType>(
                    RelayEnvironment,
                    PriceCardQuery,
                    {},
                    { fetchPolicy: 'store-and-network' },
                ),
            }),
        },
        {
            path: ORDERS_PATH,
            element: <CustomerOrders />,
            loader: () => ({
                ordersQueryRef: loadQuery<OrdersTableCustomerQueryType>(
                    RelayEnvironment,
                    OrdersTableCustomerQuery,
                    {
                        orderBy: 'CREATED',
                        orderDir: 'DESC',
                    },
                    { fetchPolicy: 'store-and-network' },
                ),
                largePriceQueryRef: loadQuery<LargePriceCardQueryType>(
                    RelayEnvironment,
                    LargePriceCardQuery,
                    {},
                    { fetchPolicy: 'store-and-network' },
                ),
            }),
        },
        ORDER_CREATE_ROUTE,
        ORDER_DETAILS_ROUTE,
    ],
}

const LOGGED_IN_PATHS = [
    {
        path: '/',
        element: <Layout />,
        loader: () => ({
            sideNavQueryRef: loadQuery<NavLinksQueryType>(
                RelayEnvironment,
                NavLinksQuery,
                {},
                { fetchPolicy: 'store-or-network' },
            ),
        }),
        children: [
            {
                path: '/',
                loader: () => {
                    if (!userType) {
                        return null
                    }
                    if (userType === 'Admin') {
                        // Force going to django admin out of the client router
                        window.location.href = DEFAULT_ROUTE_FOR_USER_TYPE[userType]
                        return null
                    }
                    return redirect(DEFAULT_ROUTE_FOR_USER_TYPE[userType])
                },
            },
            ...(userType ? ROUTES_FOR_USER_TYPE[userType] : []),
        ],
    },
]

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)
const router = sentryCreateBrowserRouter([
    {
        path: '*',
        element: <NotFound />,
    },
    {
        path: LOGIN_PATH,
        element: <Auth />,
        children: [
            {
                path: '',
                element: <Login />,
            },
            {
                path: 'confirm',
                element: <Confirm />,
            },
        ],
    },
    ...(userType
        ? LOGGED_IN_PATHS
        : [
            {
                // Show blank page, RelayEnvironment will redirect to login
                //
                // PATCH: show a fallback page with a button to login,
                // because the RelayEnvironment could skip the redirection due to errors
                path: '/',
                element: (
                    <Link
                        href={LOGIN_PATH}
                    >
                        Go to login
                    </Link>
                ),
            },
        ]),
])

export default router
