import { graphql, usePaginationFragment, usePreloadedQuery, PreloadedQuery } from 'react-relay'
import { TripsTable_providerViewer$key } from '@/__generated__/TripsTable_providerViewer.graphql.ts'
import { useLoaderData } from 'react-router-dom'
import { CompensationType, TripsTableProviderQuery } from '@/__generated__/TripsTableProviderQuery.graphql.ts'
import { TripsTablePaginationProviderQuery } from '@/__generated__/TripsTablePaginationProviderQuery.graphql.ts'
import DataGrid from '@/components/DataGrid'
import notEmpty from '@/types/notEmpty'
import useEffectSkipFirstRender from '@/hooks/useEffectSkipFirstRender'
import { format, parseISO } from 'date-fns'
import { Chip } from '@mui/material'

export default function TripsTable({
    searchTerm,
    isCustomerBuyer,
}: {
    searchTerm: string
    isCustomerBuyer: CompensationType
}) {
    const { tripsQueryRef } = useLoaderData() as {
        tripsQueryRef: PreloadedQuery<TripsTableProviderQuery>
    }
    const { viewer } = usePreloadedQuery<TripsTableProviderQuery>(
        graphql`
            query TripsTableProviderQuery($searchTerm: String, $isCustomerBuyer: CompensationType!) {
                viewer {
                    ...TripsTable_providerViewer @arguments(searchTerm: $searchTerm, isCustomerBuyer: $isCustomerBuyer)
                }
            }
        `,
        tripsQueryRef,
    )

    const {
        data: providerViewer,
        hasNext,
        loadNext,
        refetch,
    } = usePaginationFragment<TripsTablePaginationProviderQuery, TripsTable_providerViewer$key>(
        graphql`
            fragment TripsTable_providerViewer on ProviderViewer
            @argumentDefinitions(
                count: { type: "Int", defaultValue: 50 }
                cursor: { type: "String" }
                searchTerm: { type: "String", defaultValue: "" }
                isCustomerBuyer: { type: "CompensationType!" }
            )
            @refetchable(queryName: "TripsTablePaginationProviderQuery") {
                trips(first: $count, after: $cursor, searchTerm: $searchTerm, isCustomerBuyer: $isCustomerBuyer)
                    @connection(key: "ProviderViewer_trips") {
                    edges {
                        node {
                            id
                            timestamp
                            allowancesRequired
                            isCustomerBuyer
                            customerCompany {
                                registeredCompanyName
                            }
                            ship {
                                imo
                            }
                        }
                    }
                }
            }
        `,
        viewer,
    )

    useEffectSkipFirstRender(() => {
        refetch({ searchTerm, isCustomerBuyer }, { fetchPolicy: 'store-and-network' })
    }, [searchTerm, isCustomerBuyer, refetch])
    const foundTrips = providerViewer?.trips.edges.map((tripEdge) => tripEdge?.node).filter(notEmpty) || []

    const gridStatusMessage = !providerViewer?.trips.edges.length
        ? !providerViewer
            ? 'There was an error'
            : searchTerm
            ? 'There are no trips matching your filters'
            : 'There are no trips'
        : ''

    return (
        <DataGrid
            scroller
            scrollerHasMore={hasNext}
            scrollerLoad={() => loadNext(50)}
            data={foundTrips.map((t) => ({
                ...t,
                shipIMO: t.ship.imo,
                customerName: t.customerCompany.registeredCompanyName,
                compensation: t.isCustomerBuyer,
            }))}
            customMessage={gridStatusMessage}
            columns={[
                { title: 'Customer', key: 'customerName' },
                { title: 'Ship IMO', key: 'shipIMO' },
                {
                    title: 'date',
                    key: 'timestamp',
                    render: (trip) => format(parseISO(trip.timestamp as string), 'd MMM yyyy, HH:mm'),
                },
                { title: 'EUA required', key: 'allowancesRequired' },
                {
                    title: 'Compensation',
                    key: 'compensation',
                    render: (item) => (
                        <Chip
                            variant="outlined"
                            color={item.isCustomerBuyer ? 'info' : 'primary'}
                            size="small"
                            label={item.isCustomerBuyer ? 'CUSTOMER' : 'OWNED'}
                            sx={{ borderRadius: 1 }}
                        />
                    ),
                },
            ]}
        />
    )
}
