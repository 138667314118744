import { Box, Card, Stack, Typography } from '@mui/material'

import { Suspense, useState } from 'react'
import OrdersTable from './OrdersTable'
import LoadingDataGrid from '@/components/loading/LoadingDataGrid'
import SearchBox from '@/components/SearchBox.tsx'

export default function Orders() {
    const [searchTerm, setSearchTerm] = useState<string>('')
    return (
        <Box>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="h3" sx={{ my: 4 }}>
                    Orders
                </Typography>
            </Stack>
            <Card sx={{ mb: 3 }}>
                <Stack direction="column" sx={{ p: 3 }}>
                    <SearchBox value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                </Stack>
                <Suspense fallback={<LoadingDataGrid />}>
                    <OrdersTable searchTerm={searchTerm} orderBy="CREATED" orderDir="DESC" />
                </Suspense>
            </Card>
        </Box>
    )
}
