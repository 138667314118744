import { InputAdornment, OutlinedInput, SvgIcon, debounce } from '@mui/material'
import { SxProps } from '@mui/system'
import SearchIcon from '@mui/icons-material/Search'

export default function SearchBox({
    value,
    onChange,
    sx,
}: {
    value: string
    onChange: React.ChangeEventHandler<HTMLInputElement>
    sx?: SxProps
}) {
    return (
        <OutlinedInput
            sx={sx}
            placeholder="Search..."
            defaultValue={value}
            startAdornment={
                <InputAdornment position="start">
                    <SvgIcon>
                        <SearchIcon />
                    </SvgIcon>
                </InputAdornment>
            }
            onChange={debounce(onChange, 200)}
        />
    )
}
