/**
 * @generated SignedSource<<a1c059dc92d3ad5a1e8d7a4d7d5fd264>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OpenOrdersTable_viewer$data = {
  readonly id: string;
  readonly openOrders: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly allowancesFilled: number | null;
        readonly allowancesOrdered: number;
        readonly id: string;
        readonly maxPrice: any;
      } | null;
    } | null>;
  };
  readonly " $fragmentType": "OpenOrdersTable_viewer";
};
export type OpenOrdersTable_viewer$key = {
  readonly " $data"?: OpenOrdersTable_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"OpenOrdersTable_viewer">;
};

import OpenOrdersTablePaginationQuery_graphql from './OpenOrdersTablePaginationQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  "openOrders"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 50,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": OpenOrdersTablePaginationQuery_graphql,
      "identifierField": "id"
    }
  },
  "name": "OpenOrdersTable_viewer",
  "selections": [
    {
      "alias": "openOrders",
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "CREATED"
        },
        {
          "kind": "Literal",
          "name": "orderDir",
          "value": "DESC"
        },
        {
          "kind": "Literal",
          "name": "selfAssignedFilter",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "stateFilter",
          "value": "ACCEPTED"
        }
      ],
      "concreteType": "OrderConnection",
      "kind": "LinkedField",
      "name": "__OpenOrdersTable_openOrders_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Order",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "allowancesOrdered",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "allowancesFilled",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "maxPrice",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "__OpenOrdersTable_openOrders_connection(orderBy:\"CREATED\",orderDir:\"DESC\",selfAssignedFilter:true,stateFilter:\"ACCEPTED\")"
    },
    (v1/*: any*/)
  ],
  "type": "OperatorViewer",
  "abstractKey": null
};
})();

(node as any).hash = "ca5efa803bd262f7f7d29b52749a7497";

export default node;
