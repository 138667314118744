import DataGrid from '@/components/DataGrid'
import { fromGlobalId } from '@/utils/relay'
import { Button } from '@mui/material'
import { graphql, usePaginationFragment } from 'react-relay'
import notEmpty from '@/types/notEmpty.ts'
import { useNavigate } from 'react-router-dom'
import { OpenOrdersTable_viewer$key } from '@/__generated__/OpenOrdersTable_viewer.graphql'
import { OpenOrdersTablePaginationQuery } from '@/__generated__/OpenOrdersTablePaginationQuery.graphql.ts'
import formatEuro from '@/utils/formatEuro.ts'

interface IProps {
    viewer: OpenOrdersTable_viewer$key
}

export default function OpenOrdersTableTable(props: IProps) {
    const { data, hasNext, loadNext } = usePaginationFragment<
        OpenOrdersTablePaginationQuery,
        OpenOrdersTable_viewer$key
    >(
        graphql`
            fragment OpenOrdersTable_viewer on OperatorViewer
            @argumentDefinitions(count: { type: "Int", defaultValue: 50 }, cursor: { type: "String" })
            @refetchable(queryName: "OpenOrdersTablePaginationQuery") {
                openOrders: orders(
                    first: $count
                    after: $cursor
                    selfAssignedFilter: true
                    stateFilter: ACCEPTED
                    orderBy: CREATED
                    orderDir: DESC
                ) @connection(key: "OpenOrdersTable_openOrders") {
                    edges {
                        node {
                            id
                            allowancesOrdered
                            allowancesFilled
                            maxPrice
                        }
                    }
                }
            }
        `,
        props.viewer,
    )
    const navigate = useNavigate()

    const foundOrders = data?.openOrders.edges?.map((orderEdge) => orderEdge?.node).filter(notEmpty) || []
    const gridStatusMessage = !data?.openOrders.edges.length
        ? !data
            ? 'There was an error'
            : 'There are no orders'
        : ''

    return (
        <DataGrid
            scroller
            scrollerHasMore={hasNext}
            scrollerLoad={() => loadNext(50)}
            data={foundOrders}
            customMessage={gridStatusMessage}
            rowClick={(order) => navigate(`/orders/${fromGlobalId(order.id).id}`)}
            columns={[
                { title: 'Order ID', key: 'id', render: (item) => fromGlobalId(item.id).id },
                { title: 'No of EUA', key: 'allowancesOrdered' },
                { title: 'Max Buy Price', key: 'maxPrice', render: (order) => formatEuro(order.maxPrice) },
                {
                    title: 'EUA to comp',
                    key: 'allowancesFilled',
                    render: (order) => order.allowancesOrdered - (order.allowancesFilled || 0),
                },
                {
                    title: '',
                    key: 'action',
                    render: () => <Button variant="contained">Open</Button>,
                },
            ]}
        />
    )
}
