/**
 * @generated SignedSource<<51abc95706299af88a635a0761854854>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OrderState = "ACCEPTED" | "COMPLETED" | "REJECTED" | "WAITING";
import { FragmentRefs } from "relay-runtime";
export type OrderStateChip_order$data = {
  readonly state: OrderState;
  readonly " $fragmentType": "OrderStateChip_order";
};
export type OrderStateChip_order$key = {
  readonly " $data"?: OrderStateChip_order$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrderStateChip_order">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderStateChip_order",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};

(node as any).hash = "530e6f54adbca8ec2b5848d4cc8872ee";

export default node;
