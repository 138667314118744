import { Alert, AlertColor, Button, CircularProgress, FormHelperText, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { object, string } from 'yup'
import { ReactNode, useState } from 'react'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import { graphql, useMutation } from 'react-relay'
import { LoginMutation } from '@/__generated__/LoginMutation.graphql'

export default function Login() {
    const [isPostLogin, setIsPostLogin] = useState(false)
    const [isLoginError, setIsLoginError] = useState(false)

    const [requestMagicLink, isInFlight] = useMutation<LoginMutation>(graphql`
        mutation LoginMutation($email: String!) {
            requestMagicLink(input: { email: $email }) {
                clientMutationId
            }
        }
    `)

    const formik = useFormik({
        initialValues: {
            email: '',
            submit: null,
        },
        //TODO: rework this to be typescript-friendly
        validationSchema: object({
            email: string().email('Must be a valid email').max(255).required('Email is required'),
        }),
        onSubmit: (values) => {
            requestMagicLink({
                variables: {
                    email: values.email,
                },
                onCompleted: (_, errors) => {
                    if (errors) {
                        setIsLoginError(true)
                    } else {
                        setIsPostLogin(true)
                    }
                },
                // TODO: pass the errors to formik?
                onError: () => setIsLoginError(true),
            })
        },
    })

    if (isPostLogin) {
        return (
            <div>
                <Typography sx={{ textAlign: 'center', mb: 1 }} variant="h6">
                    A magic link was sent to{' '}
                    <Typography sx={{ color: 'primary.main' }} variant="h6" component="span">
                        {formik.values.email}
                    </Typography>
                </Typography>
                <Typography sx={{ textAlign: 'center' }} variant="h6">
                    Please check your email for further instructions
                </Typography>
                <Alert color={'primary' as AlertColor} severity="info" sx={{ mt: 2 }}>
                    Make sure to check filters and folders like <b>Spam</b> or <b>Junk</b>
                </Alert>
            </div>
        )
    }

    return (
        <div>
            <Typography variant="h5" sx={{ mb: 2 }}>
                Login
            </Typography>
            <form noValidate onSubmit={formik.handleSubmit}>
                {isLoginError ? (
                    <Typography color="error" sx={{ mb: 1 }} variant="body2">
                        Oops, that didn't to work. Please try again.
                    </Typography>
                ) : null}
                <TextField
                    error={!!(formik.touched.email && formik.errors.email)}
                    fullWidth
                    helperText={formik.touched.email && formik.errors.email}
                    label="Email Address"
                    name="email"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="email"
                    value={formik.values.email}
                />
                {formik.errors.submit && (
                    <Typography color="error" sx={{ mt: 1 }} variant="body2">
                        {formik.errors.submit as ReactNode}
                    </Typography>
                )}
                <FormHelperText sx={{ mt: 1 }}>
                    <AutoFixHighIcon sx={{ height: '0.8rem' }} />
                    We’ll send you a magic link for a password-free sign in
                </FormHelperText>
                <Button
                    fullWidth
                    size="large"
                    sx={{ mt: 3, height: '3rem' }}
                    type="submit"
                    variant="contained"
                    disabled={isInFlight}
                >
                    {isInFlight ? <CircularProgress color="inherit" size="1.5rem" /> : 'Continue'}
                </Button>
            </form>
        </div>
    )
}
