import { graphql, usePaginationFragment, usePreloadedQuery, PreloadedQuery } from 'react-relay'
import { CustomersTable_providerViewer$key } from '@/__generated__/CustomersTable_providerViewer.graphql.ts'
import { useLoaderData } from 'react-router-dom'
import {
    CompaniesOrder,
    CustomersTableProviderQuery,
    OrderDirection,
} from '@/__generated__/CustomersTableProviderQuery.graphql.ts'
import { CustomersTablePaginationProviderQuery } from '@/__generated__/CustomersTablePaginationProviderQuery.graphql.ts'
import DataGrid from '@/components/DataGrid'
import notEmpty from '@/types/notEmpty'
import useEffectSkipFirstRender from '@/hooks/useEffectSkipFirstRender'
import { fromGlobalId } from '@/utils/relay.ts'
import BooleanIndicator from '@/components/BooleanIndicators.tsx'

export default function CustomersTable({
    searchTerm,
    orderBy,
    orderDir,
}: {
    searchTerm: string
    orderBy: CompaniesOrder
    orderDir: OrderDirection
}) {
    const { customersQueryRef } = useLoaderData() as { customersQueryRef: PreloadedQuery<CustomersTableProviderQuery> }
    const { viewer } = usePreloadedQuery<CustomersTableProviderQuery>(
        graphql`
            query CustomersTableProviderQuery(
                $searchTerm: String
                $orderBy: CompaniesOrder!
                $orderDir: OrderDirection!
            ) {
                viewer {
                    ...CustomersTable_providerViewer
                        @arguments(searchTerm: $searchTerm, orderBy: $orderBy, orderDir: $orderDir)
                }
            }
        `,
        customersQueryRef,
    )

    const {
        data: providerViewer,
        hasNext,
        loadNext,
        refetch,
    } = usePaginationFragment<CustomersTablePaginationProviderQuery, CustomersTable_providerViewer$key>(
        graphql`
            fragment CustomersTable_providerViewer on ProviderViewer
            @argumentDefinitions(
                count: { type: "Int", defaultValue: 50 }
                cursor: { type: "String" }
                searchTerm: { type: "String", defaultValue: "" }
                orderBy: { type: "CompaniesOrder!" }
                orderDir: { type: "OrderDirection!" }
            )
            @refetchable(queryName: "CustomersTablePaginationProviderQuery") {
                customers(
                    first: $count
                    after: $cursor
                    searchTerm: $searchTerm
                    orderBy: $orderBy
                    orderDir: $orderDir
                ) @connection(key: "ProviderCompany_customers") {
                    edges {
                        node {
                            id
                            registeredCompanyName
                            isBuyingEua
                            tripsCount
                            euaToSurrender
                            euaPurchased
                        }
                    }
                }
            }
        `,
        viewer,
    )

    useEffectSkipFirstRender(() => {
        refetch({ searchTerm, orderBy, orderDir }, { fetchPolicy: 'store-and-network' })
    }, [searchTerm, orderBy, orderDir, refetch])

    const foundCustomers =
        providerViewer?.customers.edges.map((customerEdge) => customerEdge?.node).filter(notEmpty) || []

    const gridStatusMessage = !providerViewer?.customers.edges.length
        ? !providerViewer
            ? 'There was an error'
            : searchTerm
            ? 'There are no customers matching your filters'
            : 'There are no customers'
        : ''

    return (
        <DataGrid
            scroller
            scrollerHasMore={hasNext}
            scrollerLoad={() => loadNext(50)}
            data={foundCustomers}
            customMessage={gridStatusMessage}
            columns={[
                { title: 'Company', key: 'registeredCompanyName' },
                { title: 'ID', key: 'id', render: (customer) => fromGlobalId(customer.id).id },
                { title: 'Number of trips', key: 'tripsCount' },
                {
                    title: 'EUA Balance',
                    key: 'euaBalance',
                    render: (c) =>
                        c.euaPurchased === 0 && c.euaToSurrender === 0 ? '' : c.euaPurchased - c.euaToSurrender,
                },
                {
                    title: 'Buys EUA',
                    key: 'isBuyingEua',
                    render: (customer) => <BooleanIndicator value={customer.isBuyingEua} />,
                },
            ]}
        />
    )
}
