import { Box, Container, Typography } from '@mui/material'

export default function NotFound() {
    return (
        <Box
            sx={{
                backgroundColor: 'background.paper',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    maxWidth: 550,
                    px: 3,
                    py: '100px',
                    width: '100%',
                }}
            >
                <Container maxWidth="xl">
                    <Typography sx={{ textAlign: 'center' }}>Page not found</Typography>
                </Container>
            </Box>
        </Box>
    )
}
