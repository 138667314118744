import { Button, Link, Typography } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { graphql, useMutation } from 'react-relay'
import { ConfirmLoginMutation } from '@/__generated__/ConfirmLoginMutation.graphql'
import { useState } from 'react'
import { LOGIN_PATH } from '@/config.ts'

export default function Login() {
    const [searchParams] = useSearchParams()
    const [isConfirmError, setIsConfirmError] = useState(false)
    const loginKey = searchParams.get('key') || ''

    const [confirmLoginKey, isInFlight] = useMutation<ConfirmLoginMutation>(graphql`
        mutation ConfirmLoginMutation($loginKey: String!) {
            confirmLoginKey(input: { loginKey: $loginKey }) {
                viewer {
                    id
                }
            }
        }
    `)

    function handleContinue() {
        confirmLoginKey({
            variables: { loginKey },
            onCompleted: (data, errors) => {
                if (errors || !data.confirmLoginKey?.viewer?.id) {
                    setIsConfirmError(true)
                } else {
                    window.location.href = '/'
                }
            },
            onError: () => setIsConfirmError(true),
        })
    }

    return (
        <div>
            {loginKey ? (
                <>
                    <Typography variant="h6" sx={{ mb: 1, textAlign: 'center' }}>
                        Click the button below to log into the platform
                    </Typography>
                </>
            ) : (
                <Typography sx={{ color: 'error.main', textAlign: 'center' }}>
                    Login key not found. Check back to your email.
                </Typography>
            )}

            {isConfirmError ? (
                <>
                    <Typography sx={{ color: 'error.main', textAlign: 'center' }}>
                        Login did not succeed, the link might have expired.
                    </Typography>
                    <Typography sx={{ textAlign: 'center' }}>
                        <Link href={LOGIN_PATH}>Go to login</Link>
                    </Typography>
                </>
            ) : null}

            <Button
                disabled={!loginKey || isInFlight || isConfirmError}
                fullWidth
                size="large"
                sx={{ mt: 3 }}
                type="submit"
                variant="contained"
                onClick={handleContinue}
            >
                Confirm login
            </Button>
        </div>
    )
}
