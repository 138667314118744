import { useState } from 'react'
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material'
import { graphql, useFragment, useMutation } from 'react-relay'
import { OperatorTransferSection_order$key } from '@/__generated__/OperatorTransferSection_order.graphql.ts'
import { OperatorTransferSection_viewer$key } from '@/__generated__/OperatorTransferSection_viewer.graphql.ts'
import { OperatorTransferSectionMutation } from '@/__generated__/OperatorTransferSectionMutation.graphql.ts'

interface IProps {
    order: OperatorTransferSection_order$key
    viewer: OperatorTransferSection_viewer$key
}

export default function OperatorTransferSection(props: IProps) {
    const order = useFragment<OperatorTransferSection_order$key>(
        graphql`
            fragment OperatorTransferSection_order on Order {
                id
                state
                isOperatorViewer
                operator {
                    id
                }
            }
        `,
        props.order,
    )
    const viewer = useFragment<OperatorTransferSection_viewer$key>(
        graphql`
            fragment OperatorTransferSection_viewer on OperatorViewer {
                operators {
                    id
                    firstName
                    lastName
                }
            }
        `,
        props.viewer,
    )

    const [transferOperator, isInFlight] = useMutation<OperatorTransferSectionMutation>(graphql`
        mutation OperatorTransferSectionMutation($orderId: ID!, $operatorId: ID!) {
            transferOperator(input: { orderId: $orderId, operatorId: $operatorId }) {
                order {
                    ...OperatorOrderDetails_order
                }
            }
        }
    `)

    const [isError, setIsError] = useState(false)
    const [selectedOperatorId, setSelectedOperatorId] = useState(order.operator?.id)

    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const openDialog = () => setIsDialogOpen(true)
    const closeDialog = () => setIsDialogOpen(false)

    const handleConfirm = () => {
        if (!selectedOperatorId) {
            return
        }
        transferOperator({
            variables: {
                orderId: order.id,
                operatorId: selectedOperatorId,
            },
            onCompleted: (data, errors) => {
                if (errors || !data.transferOperator?.order) {
                    setIsError(true)
                } else {
                    closeDialog()
                }
            },
            onError: () => {
                setIsError(true)
            },
        })
    }

    if (order.state !== 'ACCEPTED' || !order.isOperatorViewer) {
        return null
    }

    return (
        <>
            <Button variant="contained" color="info" sx={{ my: 2 }} onClick={openDialog}>
                Transfer Operator
            </Button>

            <Dialog open={isDialogOpen} keepMounted aria-describedby="alert-dialog-slide-description">
                <DialogTitle>Transfer to operator</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" component="div" textAlign="center">
                        <Typography>Which operator do you want to transfer the order to?</Typography>
                        {isError ? (
                            <Typography variant="body2" color="error" mt={2}>
                                Something went wrong. Please try again later.
                            </Typography>
                        ) : null}
                        <TextField
                            label="Operator"
                            name="operator"
                            select
                            value={selectedOperatorId}
                            sx={{ width: 200, mt: 2 }}
                            onChange={(e) => setSelectedOperatorId(e.target.value)}
                        >
                            {viewer.operators.map((operator) => (
                                <MenuItem key={operator.id} value={operator.id}>
                                    {`${operator.firstName} ${operator.lastName}`}
                                </MenuItem>
                            ))}
                        </TextField>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button variant="contained" onClick={handleConfirm} disabled={isInFlight} sx={{ width: '6rem' }}>
                        {isInFlight ? <CircularProgress color="inherit" size="1.5rem" /> : 'Confirm'}
                    </Button>
                    <Button variant="contained" color="error" onClick={closeDialog} sx={{ width: '6rem' }}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
