import { graphql, usePaginationFragment, usePreloadedQuery, PreloadedQuery } from 'react-relay'
import { TripsTable_customerViewer$key } from '@/__generated__/TripsTable_customerViewer.graphql.ts'
import { useLoaderData } from 'react-router-dom'
import { TripsTableCustomerQuery } from '@/__generated__/TripsTableCustomerQuery.graphql.ts'
import { TripsTablePaginationCustomerQuery } from '@/__generated__/TripsTablePaginationCustomerQuery.graphql.ts'
import DataGrid from '@/components/DataGrid'
import notEmpty from '@/types/notEmpty'
import useEffectSkipFirstRender from '@/hooks/useEffectSkipFirstRender'
import { format, parseISO } from 'date-fns'

export default function TripsTable({ searchTerm }: { searchTerm: string }) {
    const { tripsQueryRef } = useLoaderData() as {
        tripsQueryRef: PreloadedQuery<TripsTableCustomerQuery>
    }
    const { viewer } = usePreloadedQuery<TripsTableCustomerQuery>(
        graphql`
            query TripsTableCustomerQuery($searchTerm: String) {
                viewer {
                    ...TripsTable_customerViewer @arguments(searchTerm: $searchTerm)
                }
            }
        `,
        tripsQueryRef,
    )

    const {
        data: customerViewer,
        hasNext,
        loadNext,
        refetch,
    } = usePaginationFragment<TripsTablePaginationCustomerQuery, TripsTable_customerViewer$key>(
        graphql`
            fragment TripsTable_customerViewer on CustomerViewer
            @argumentDefinitions(
                count: { type: "Int", defaultValue: 50 }
                cursor: { type: "String" }
                searchTerm: { type: "String", defaultValue: "" }
            )
            @refetchable(queryName: "TripsTablePaginationCustomerQuery") {
                trips(first: $count, after: $cursor, searchTerm: $searchTerm) @connection(key: "CustomerViewer_trips") {
                    edges {
                        node {
                            id
                            timestamp
                            destination
                            allowancesRequired
                            ship {
                                imo
                            }
                        }
                    }
                }
            }
        `,
        viewer,
    )

    useEffectSkipFirstRender(() => {
        refetch({ searchTerm }, { fetchPolicy: 'store-and-network' })
    }, [searchTerm, refetch])

    const foundTrips = customerViewer?.trips.edges.map((tripEdge) => tripEdge?.node).filter(notEmpty) || []
    const gridStatusMessage = !customerViewer?.trips.edges.length
        ? !customerViewer
            ? 'There was an error'
            : searchTerm
            ? 'There are no trips matching your filters'
            : 'There are no trips'
        : ''

    return (
        <DataGrid
            scroller
            scrollerHasMore={hasNext}
            scrollerLoad={() => loadNext(50)}
            data={foundTrips.map((t) => ({
                ...t,
                shipIMO: t.ship.imo,
            }))}
            customMessage={gridStatusMessage}
            columns={[
                {
                    title: 'date',
                    key: 'timestamp',
                    render: (trip) => format(parseISO(trip.timestamp as string), 'd MMM yyyy, HH:mm'),
                },
                { title: 'Ship IMO', key: 'shipIMO' },
                { title: 'Destination', key: 'destination' },
                { title: 'EUA required', key: 'allowancesRequired' },
            ]}
        />
    )
}
