import { Box, Card, Divider, Stack, Tab, Tabs, Typography } from '@mui/material'

import { Suspense, useState } from 'react'
import SearchBox from '@/components/SearchBox'
import TripsTable from './TripsTable'
import PriceCard from '@/components/PriceCard'
import { CompensationType } from '@/__generated__/TripsTableProviderQuery.graphql'
import LoadingDataGrid from '@/components/loading/LoadingDataGrid'

const tabs: { label: string; value: CompensationType }[] = [
    { label: 'All Compensations', value: 'ALL' },
    { label: 'Owned Compensations', value: 'PROVIDER_BUYER' },
    { label: 'Customers Compensations', value: 'CUSTOMER_BUYER' },
]

export default function Trips() {
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [compensationFilter, setCompensationFilter] = useState<CompensationType>('ALL')
    return (
        <Box>
            <PriceCard />
            <Typography variant="h3" sx={{ my: 4 }}>
                Trips
            </Typography>
            <Card sx={{ mb: 3 }}>
                <Tabs
                    indicatorColor="primary"
                    onChange={(_, value: CompensationType) => setCompensationFilter(value)}
                    scrollButtons="auto"
                    sx={{ px: 3 }}
                    textColor="primary"
                    value={compensationFilter}
                    variant="scrollable"
                >
                    {tabs.map((tab) => (
                        <Tab key={tab.value} label={tab.label} value={tab.value} />
                    ))}
                </Tabs>
                <Divider />
                <Stack direction="column" sx={{ p: 3 }}>
                    <SearchBox value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                </Stack>
                <Suspense fallback={<LoadingDataGrid />}>
                    <TripsTable searchTerm={searchTerm} isCustomerBuyer={compensationFilter} />
                </Suspense>
            </Card>
        </Box>
    )
}
