import { Environment, Network, RecordSource, Store, FetchFunction, PayloadError } from 'relay-runtime'
import { GRAPHQL_PATH, LOGIN_PATH } from '@/config.ts'
import Cookies from 'js-cookie'

const fetchFn: FetchFunction = async (request, variables) => {
    const graphqlPath = request?.name ? `${GRAPHQL_PATH}?${request.name}` : GRAPHQL_PATH
    const csrfToken = Cookies.get('csrftoken')
    try {
        const resp = await fetch(graphqlPath, {
            method: 'POST',
            headers: {
                Accept: 'application/graphql-response+json; charset=utf-8, application/json; charset=utf-8',
                'Content-Type': 'application/json',
                ...(csrfToken ? { 'X-CSRFToken': csrfToken } : {}),
            },
            credentials: 'same-origin',
            body: JSON.stringify({
                query: request.text, // <-- The GraphQL document composed by Relay
                variables,
            }),
        })
        const result = (await resp.json()) as {
            data: { viewer: unknown; node: unknown } | undefined
            errors: PayloadError[]
        }
        if (!result || !result.data) {
            return result
        }

        if (
            request.operationKind === 'query' &&
            !result.data.viewer &&
            !result.data.node &&
            !result.errors &&
            !window.location.pathname.includes(LOGIN_PATH)
        ) {
            // User is logged out
            location.href = `${location.origin}${LOGIN_PATH}`
        }

        return result
    } catch (e) {
        console.error(e)
        return { data: { viewer: null }, errors: [{ message: 'Could not fetch data' }] }
    }
}

function createRelayEnvironment() {
    return new Environment({
        network: Network.create(fetchFn),
        store: new Store(new RecordSource()),
    })
}

const RelayEnvironment = createRelayEnvironment()

export default RelayEnvironment
