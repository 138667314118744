import { Box, Button, Card, Stack, Typography } from '@mui/material'

import { Suspense } from 'react'
import OrdersTable from './OrdersTable'
import LargePriceCard from '@/components/LargePriceCard'
import LoadingDataGrid from '@/components/loading/LoadingDataGrid'
import { useNavigate } from 'react-router-dom'
import { ORDERS_CREATE_PATH } from '@/config.ts'

export default function Orders() {
    const navigate = useNavigate()
    return (
        <Box>
            <LargePriceCard />
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="h3" sx={{ my: 4 }}>
                    Orders
                </Typography>
                <div>
                    <Button
                        size="medium"
                        sx={{ mt: 5 }}
                        type="submit"
                        variant="contained"
                        onClick={() => navigate(ORDERS_CREATE_PATH)}
                    >
                        Create custom order
                    </Button>
                </div>
            </Stack>
            <Card sx={{ mb: 3 }}>
                <Suspense fallback={<LoadingDataGrid />}>
                    <OrdersTable orderBy="CREATED" orderDir="DESC" />
                </Suspense>
            </Card>
        </Box>
    )
}
