import DataGrid from '@/components/DataGrid'
import formatEuro from '@/utils/formatEuro'
import { fromGlobalId } from '@/utils/relay'
import { Button } from '@mui/material'
import { graphql, useFragment } from 'react-relay'
import { IncomingOrdersTable_viewer$key } from '@/__generated__/IncomingOrdersTable_viewer.graphql.ts'
import notEmpty from '@/types/notEmpty.ts'
import { useNavigate } from 'react-router-dom'

interface IProps {
    viewer: IncomingOrdersTable_viewer$key
}

export default function IncomingOrdersTable(props: IProps) {
    const viewer = useFragment<IncomingOrdersTable_viewer$key>(
        graphql`
            fragment IncomingOrdersTable_viewer on OperatorViewer {
                incomingOrders: orders(first: 100, stateFilter: WAITING, orderBy: CREATED, orderDir: DESC)
                    @connection(key: "IncomingOrdersTable_incomingOrders") {
                    edges {
                        node {
                            id
                            allowancesOrdered
                            maxPrice
                        }
                    }
                }
            }
        `,
        props.viewer,
    )
    const navigate = useNavigate()

    const foundOrders = viewer.incomingOrders.edges.map((orderEdge) => orderEdge?.node).filter(notEmpty) || []
    const gridStatusMessage = !viewer?.incomingOrders.edges.length
        ? !viewer
            ? 'There was an error'
            : 'There are no incoming orders'
        : ''

    return (
        <DataGrid
            data={foundOrders}
            customMessage={gridStatusMessage}
            rowClick={(order) => navigate(`/orders/${fromGlobalId(order.id).id}`)}
            columns={[
                { title: 'Order ID', key: 'id', render: (item) => fromGlobalId(item.id).id },
                { title: 'No of EUA', key: 'allowancesOrdered' },
                { title: 'Max Buy Price', key: 'maxPrice', render: (item) => formatEuro(item.maxPrice) },
                {
                    title: 'Total Price',
                    key: 'total',
                    render: (item) => formatEuro(item.allowancesOrdered * item.maxPrice),
                },
                {
                    title: '',
                    key: 'action',
                    render: () => <Button variant="contained">Answer</Button>,
                },
            ]}
        />
    )
}
