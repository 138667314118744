/**
 * @generated SignedSource<<0575e9a5afc4b16da08ca448721c7df3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UserType = "Admin" | "Customer" | "Operator" | "Provider";
export type PriceCardQuery$variables = {};
export type PriceCardQuery$data = {
  readonly viewer: {
    readonly customerCompany?: {
      readonly euaPurchased: number;
      readonly euaToSurrender: number;
    };
    readonly euaPrice: any;
    readonly providerCompany?: {
      readonly euaPurchased: number | null;
      readonly euaToSurrender: number | null;
    };
    readonly userType: UserType;
  } | null;
};
export type PriceCardQuery = {
  response: PriceCardQuery$data;
  variables: PriceCardQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "euaPrice",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userType",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "euaToSurrender",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "euaPurchased",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  (v2/*: any*/),
  (v3/*: any*/),
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PriceCardQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomerCompany",
                "kind": "LinkedField",
                "name": "customerCompany",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "type": "CustomerViewer",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProviderCompany",
                "kind": "LinkedField",
                "name": "providerCompany",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "type": "ProviderViewer",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PriceCardQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomerCompany",
                "kind": "LinkedField",
                "name": "customerCompany",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              }
            ],
            "type": "CustomerViewer",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProviderCompany",
                "kind": "LinkedField",
                "name": "providerCompany",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              }
            ],
            "type": "ProviderViewer",
            "abstractKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f7d382e2cb82c2a610ef3d870dcf98c3",
    "id": null,
    "metadata": {},
    "name": "PriceCardQuery",
    "operationKind": "query",
    "text": "query PriceCardQuery {\n  viewer {\n    __typename\n    euaPrice\n    userType\n    ... on CustomerViewer {\n      customerCompany {\n        euaToSurrender\n        euaPurchased\n        id\n      }\n    }\n    ... on ProviderViewer {\n      providerCompany {\n        euaToSurrender\n        euaPurchased\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "9729378dcedd5e43f0d2d1ac73c824b5";

export default node;
