/**
 * @generated SignedSource<<2b74cdfbd0263b0a43ce94b6408a6aae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OperatorTransactionsSectionMutation$variables = {
  allowancesFilled: number;
  orderId: string;
  price: any;
};
export type OperatorTransactionsSectionMutation$data = {
  readonly addTransaction: {
    readonly order: {
      readonly " $fragmentSpreads": FragmentRefs<"OperatorOrderDetails_order">;
    };
  } | null;
};
export type OperatorTransactionsSectionMutation = {
  response: OperatorTransactionsSectionMutation$data;
  variables: OperatorTransactionsSectionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "allowancesFilled"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "price"
},
v3 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "allowancesFilled",
        "variableName": "allowancesFilled"
      },
      {
        "kind": "Variable",
        "name": "orderId",
        "variableName": "orderId"
      },
      {
        "kind": "Variable",
        "name": "price",
        "variableName": "price"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allowancesFilled",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "registeredCompanyName",
    "storageKey": null
  },
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OperatorTransactionsSectionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "AddTransactionPayload",
        "kind": "LinkedField",
        "name": "addTransaction",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Order",
            "kind": "LinkedField",
            "name": "order",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "OperatorOrderDetails_order"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "OperatorTransactionsSectionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "AddTransactionPayload",
        "kind": "LinkedField",
        "name": "addTransaction",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Order",
            "kind": "LinkedField",
            "name": "order",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "state",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "allowancesOrdered",
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "maxPrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "created",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomerCompany",
                "kind": "LinkedField",
                "name": "customerCompany",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProviderCompany",
                "kind": "LinkedField",
                "name": "providerCompany",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isOperatorViewer",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Transaction",
                "kind": "LinkedField",
                "name": "transactions",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "price",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Operator",
                "kind": "LinkedField",
                "name": "operator",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fd81ac8f1c4da01fcd937897a769a1dd",
    "id": null,
    "metadata": {},
    "name": "OperatorTransactionsSectionMutation",
    "operationKind": "mutation",
    "text": "mutation OperatorTransactionsSectionMutation(\n  $orderId: ID!\n  $allowancesFilled: Int!\n  $price: Decimal!\n) {\n  addTransaction(input: {orderId: $orderId, allowancesFilled: $allowancesFilled, price: $price}) {\n    order {\n      ...OperatorOrderDetails_order\n      id\n    }\n  }\n}\n\nfragment OperatorOrderDetails_order on Order {\n  id\n  state\n  allowancesOrdered\n  allowancesFilled\n  maxPrice\n  created\n  customerCompany {\n    registeredCompanyName\n    id\n  }\n  providerCompany {\n    registeredCompanyName\n    id\n  }\n  ...OperatorTransactionsSection_order\n  ...OperatorTransferSection_order\n}\n\nfragment OperatorTransactionsSection_order on Order {\n  id\n  state\n  isOperatorViewer\n  allowancesOrdered\n  allowancesFilled\n  transactions {\n    id\n    allowancesFilled\n    price\n  }\n}\n\nfragment OperatorTransferSection_order on Order {\n  id\n  state\n  isOperatorViewer\n  operator {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "4f29230791dd224ab0fc43a4d83d40a4";

export default node;
