import { Box, Paper, Skeleton, Typography } from '@mui/material'
import { graphql, PreloadedQuery, usePreloadedQuery } from 'react-relay'
import { useLoaderData } from 'react-router-dom'
import { Suspense } from 'react'
import { LargePriceCardQuery } from '@/__generated__/LargePriceCardQuery.graphql.ts'
import formatEuro from '@/utils/formatEuro'

function LargePriceCard() {
    const { largePriceQueryRef } = useLoaderData() as { largePriceQueryRef: PreloadedQuery<LargePriceCardQuery> }
    const { viewer } = usePreloadedQuery<LargePriceCardQuery>(
        graphql`
            query LargePriceCardQuery {
                viewer {
                    euaPrice
                    userType
                    ... on CustomerViewer {
                        customerCompany {
                            euaToSurrender
                            euaPurchased
                        }
                    }
                    ... on ProviderViewer {
                        providerCompany {
                            euaToSurrender
                            euaPurchased
                            customersEuaToSurrender
                            customersEuaPurchased
                        }
                    }
                }
            }
        `,
        largePriceQueryRef,
    )

    if (!viewer) {
        return null
    }

    const showBalance =
        viewer.userType === 'Provider' ||
        (viewer.userType === 'Customer' &&
            (viewer.customerCompany?.euaToSurrender || viewer.customerCompany?.euaPurchased))

    const euaBalance =
        viewer.userType === 'Provider'
            ? (viewer.providerCompany?.euaPurchased || 0) - (viewer.providerCompany?.euaToSurrender || 0)
            : viewer.userType === 'Customer'
            ? (viewer.customerCompany?.euaPurchased || 0) - (viewer.customerCompany?.euaToSurrender || 0)
            : 0

    return (
        <Paper
            variant="outlined"
            sx={{
                borderRadius: 'sm',
                p: 2,
                my: 2,
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            <Box sx={{ mx: 2.5, textAlign: 'center' }}>
                <Typography variant="h6">Live EUA price</Typography>
                <Typography variant="h4" color="primary">
                    {formatEuro(viewer.euaPrice) || '-'}
                </Typography>
            </Box>
            {showBalance ? (
                <>
                    <Box sx={{ mx: 2.5, textAlign: 'center' }}>
                        <Typography variant="h6">EUA Balance</Typography>
                        <Typography variant="h4" color="primary">
                            {euaBalance}
                        </Typography>
                    </Box>
                    <Box sx={{ mx: 2.5, textAlign: 'center' }}>
                        <Typography variant="h6">EUA to surrender</Typography>
                        <Typography variant="h4" color="primary">
                            {viewer.userType === 'Provider'
                                ? viewer.providerCompany?.euaToSurrender
                                : viewer.userType === 'Customer'
                                ? viewer.customerCompany?.euaToSurrender
                                : null}
                        </Typography>
                    </Box>
                    <Box sx={{ mx: 2.5, textAlign: 'center' }}>
                        <Typography variant="h6">EUA Purchased</Typography>
                        <Typography variant="h4" color="primary">
                            {viewer.userType === 'Provider'
                                ? viewer.providerCompany?.euaPurchased
                                : viewer.userType === 'Customer'
                                ? viewer.customerCompany?.euaPurchased
                                : null}
                        </Typography>
                    </Box>
                </>
            ) : null}
            {viewer.userType === 'Provider' ? (
                <Box sx={{ mx: 2.5, textAlign: 'center' }}>
                    <Typography variant="h6">Customers EUA</Typography>
                    <Typography variant="h4" color="primary">
                        {viewer.providerCompany?.customersEuaPurchased} /{' '}
                        {viewer.providerCompany?.customersEuaToSurrender}
                    </Typography>
                </Box>
            ) : null}
        </Paper>
    )
}

export default function LargePriceCardWithLoading() {
    return (
        <Suspense
            fallback={
                <Paper
                    variant="outlined"
                    sx={{
                        borderRadius: 'sm',
                        p: 2,
                        my: 2,
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box sx={{ mx: 2.5, textAlign: 'center' }}>
                        <Typography variant="h6">Live EUA price</Typography>
                        <Skeleton height={38} />
                    </Box>
                </Paper>
            }
        >
            <LargePriceCard />
        </Suspense>
    )
}
