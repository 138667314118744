/**
 * @generated SignedSource<<64d683804d8468f5afe8f68d96450885>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ConfirmLoginMutation$variables = {
  loginKey: string;
};
export type ConfirmLoginMutation$data = {
  readonly confirmLoginKey: {
    readonly viewer: {
      readonly id: string;
    } | null;
  } | null;
};
export type ConfirmLoginMutation = {
  response: ConfirmLoginMutation$data;
  variables: ConfirmLoginMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "loginKey"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "loginKey",
        "variableName": "loginKey"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ConfirmLoginMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ConfirmLoginKeyPayload",
        "kind": "LinkedField",
        "name": "confirmLoginKey",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ConfirmLoginMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ConfirmLoginKeyPayload",
        "kind": "LinkedField",
        "name": "confirmLoginKey",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d89ce6c50b32b4a4d15ede5aa65b7676",
    "id": null,
    "metadata": {},
    "name": "ConfirmLoginMutation",
    "operationKind": "mutation",
    "text": "mutation ConfirmLoginMutation(\n  $loginKey: String!\n) {\n  confirmLoginKey(input: {loginKey: $loginKey}) {\n    viewer {\n      __typename\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "405dd608a12d1c5ad4b976ac34547cf1";

export default node;
