import { graphql, usePaginationFragment, usePreloadedQuery, PreloadedQuery } from 'react-relay'
import { CustomersTable_operatorViewer$key } from '@/__generated__/CustomersTable_operatorViewer.graphql.ts'
import { useLoaderData } from 'react-router-dom'
import {
    CompaniesOrder,
    CustomersTableOperatorQuery,
    OrderDirection,
} from '@/__generated__/CustomersTableOperatorQuery.graphql.ts'
import { CustomersTablePaginationOperatorQuery } from '@/__generated__/CustomersTablePaginationOperatorQuery.graphql.ts'
import DataGrid from '@/components/DataGrid'
import notEmpty from '@/types/notEmpty'
import useEffectSkipFirstRender from '@/hooks/useEffectSkipFirstRender'
import { fromGlobalId } from '@/utils/relay.ts'

export default function CustomersTable({
    searchTerm,
    orderBy,
    orderDir,
}: {
    searchTerm: string
    orderBy: CompaniesOrder
    orderDir: OrderDirection
}) {
    const { customersQueryRef } = useLoaderData() as { customersQueryRef: PreloadedQuery<CustomersTableOperatorQuery> }
    const { viewer } = usePreloadedQuery<CustomersTableOperatorQuery>(
        graphql`
            query CustomersTableOperatorQuery(
                $searchTerm: String
                $orderBy: CompaniesOrder!
                $orderDir: OrderDirection!
            ) {
                viewer {
                    ...CustomersTable_operatorViewer
                        @arguments(searchTerm: $searchTerm, orderBy: $orderBy, orderDir: $orderDir)
                }
            }
        `,
        customersQueryRef,
    )

    const {
        data: operatorViewer,
        hasNext,
        loadNext,
        refetch,
    } = usePaginationFragment<CustomersTablePaginationOperatorQuery, CustomersTable_operatorViewer$key>(
        graphql`
            fragment CustomersTable_operatorViewer on OperatorViewer
            @argumentDefinitions(
                count: { type: "Int", defaultValue: 50 }
                cursor: { type: "String" }
                searchTerm: { type: "String", defaultValue: "" }
                orderBy: { type: "CompaniesOrder!" }
                orderDir: { type: "OrderDirection!" }
            )
            @refetchable(queryName: "CustomersTablePaginationOperatorQuery") {
                customers(
                    first: $count
                    after: $cursor
                    searchTerm: $searchTerm
                    orderBy: $orderBy
                    orderDir: $orderDir
                ) @connection(key: "OperatorCompany_customers") {
                    edges {
                        node {
                            id
                            registeredCompanyName
                            legalRepresentativeName
                            contactEmail
                            contactPhone
                            created
                        }
                    }
                }
            }
        `,
        viewer,
    )

    useEffectSkipFirstRender(() => {
        refetch({ searchTerm, orderBy, orderDir }, { fetchPolicy: 'store-and-network' })
    }, [searchTerm, orderBy, orderDir, refetch])

    const foundCustomers =
        operatorViewer?.customers.edges.map((customerEdge) => customerEdge?.node).filter(notEmpty) || []

    const gridStatusMessage = !operatorViewer?.customers.edges.length
        ? !operatorViewer
            ? 'There was an error'
            : searchTerm
            ? 'There are no customers matching your filters'
            : 'There are no customers'
        : ''

    return (
        <DataGrid
            scroller
            scrollerHasMore={hasNext}
            scrollerLoad={() => loadNext(50)}
            data={foundCustomers}
            customMessage={gridStatusMessage}
            columns={[
                { title: 'Customer ID', key: 'id', render: (customer) => fromGlobalId(customer.id).id },
                { title: 'Name', key: 'registeredCompanyName' },
                { title: 'Name of representative', key: 'legalRepresentativeName' },
                { title: 'Phone', key: 'contactPhone' },
                { title: 'Email', key: 'contactEmail' },
            ]}
        />
    )
}
