import { Box, Card, CircularProgress, IconButton, SvgIcon, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useLoaderData, useNavigate, useParams } from 'react-router-dom'
import formatEuro from '@/utils/formatEuro.ts'
import { graphql, loadQuery, PreloadedQuery, usePreloadedQuery } from 'react-relay'
import ClientOrderDetailsQueryGraphql, {
    ClientOrderDetailsQuery,
} from '@/__generated__/ClientOrderDetailsQuery.graphql.ts'
import { ORDERS_PATH } from '@/config.ts'
import { Suspense } from 'react'
import RemainingTimeTicker from '@/components/RemainingTimeTicker'
import RelayEnvironment from '@/RelayEnvironment.ts'
import { toGlobalId } from '@/utils/relay.ts'

function ClientOrderDetails() {
    const { id } = useParams()
    const navigate = useNavigate()
    const { orderDetailsQueryRef } = useLoaderData() as {
        orderDetailsQueryRef: PreloadedQuery<ClientOrderDetailsQuery>
    }
    const { node: order } = usePreloadedQuery(
        graphql`
            query ClientOrderDetailsQuery($id: ID!) {
                node(id: $id) {
                    ... on Order {
                        state
                        allowancesOrdered
                        maxPrice
                        created
                        isOwnerViewer
                    }
                }
            }
        `,
        orderDetailsQueryRef,
    )

    if (!order) {
        return null
    }

    function handleTimeout() {
        // Give Celery 3 seconds to autoreject order
        setTimeout(function () {
            loadQuery(
                RelayEnvironment,
                ClientOrderDetailsQueryGraphql,
                { id: toGlobalId('Order', id || '') },
                { fetchPolicy: 'store-and-network' },
            )
        }, 3000)
    }

    let content = null

    const processingHeader = (
        <Box sx={{ flex: 1 }}>
            <Typography variant="h4" sx={{ mb: 2 }}>
                {order.isOwnerViewer ? 'Your order' : 'Order'} of {order.allowancesOrdered} EUAs is being processed
            </Typography>
            <Typography variant="body1">
                We will buy EUAs only if the market price is under {order.maxPrice ? formatEuro(order.maxPrice) : '-'}
            </Typography>
        </Box>
    )

    if (order.state === 'REJECTED') {
        content = (
            <Box sx={{ flex: 1 }}>
                <Typography variant="h4" sx={{ mb: 2 }}>
                    Order of {order.allowancesOrdered} EUAs was rejected
                </Typography>
                <Typography variant="body1">Please place another order</Typography>
            </Box>
        )
    }
    if (order.state === 'WAITING') {
        content = (
            <>
                {processingHeader}
                <Box sx={{ flex: 1 }}>
                    <Typography variant="h4">Waiting for confirmation</Typography>
                    <Typography variant="h4" sx={{ mb: 2 }}>
                        <RemainingTimeTicker date={order.created as string} handleTimeout={handleTimeout} />
                    </Typography>
                    <Typography variant="body1">
                        One of our operators will soon confirm your order as soon as possible. If not confirmed or
                        rejected your order is valid for 15 minutes before being automatically discarded.
                    </Typography>
                </Box>
            </>
        )
    }
    if (order.state === 'ACCEPTED') {
        content = (
            <>
                {processingHeader}
                <Box sx={{ flex: 1 }}>
                    <Typography variant="h4">Order confirmed!</Typography>
                    <Typography variant="body1">Your order is valid until 6pm CET closing market time</Typography>
                </Box>
            </>
        )
    }
    if (order.state === 'COMPLETED') {
        content = (
            <Box sx={{ flex: 1 }}>
                <Typography variant="h4" sx={{ mb: 2 }}>
                    Your order of {order.allowancesOrdered} EUAs was completed
                </Typography>
                <Typography variant="body1">Please place another order</Typography>
            </Box>
        )
    }
    return (
        <Card sx={{ p: 5 }}>
            <IconButton
                onClick={() => navigate(ORDERS_PATH)}
                sx={{
                    mr: 0.5,
                    my: 2,
                }}
            >
                <SvgIcon fontSize="small">
                    <ArrowBackIcon />
                </SvgIcon>
            </IconButton>
            <Box
                sx={{
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: 'min(calc(100vh - 100px), 500px)',
                    maxWidth: 600,
                    margin: 'auto',
                    justifyContent: 'space-between',
                }}
            >
                {content}
            </Box>
        </Card>
    )
}

export default function OrderDetailsWithLoading() {
    return (
        <Suspense
            fallback={
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '3rem',
                    }}
                >
                    <CircularProgress color="inherit" />
                </div>
            }
        >
            <ClientOrderDetails />
        </Suspense>
    )
}
