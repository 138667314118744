/**
 * @generated SignedSource<<7a35cc53c7727139e359aed3fb6eacca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OperatorOrderDetailsQuery$variables = {
  id: string;
};
export type OperatorOrderDetailsQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"OperatorOrderDetails_order">;
  } | null;
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"OperatorTransferSection_viewer">;
  } | null;
};
export type OperatorOrderDetailsQuery = {
  response: OperatorOrderDetailsQuery$data;
  variables: OperatorOrderDetailsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allowancesFilled",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "registeredCompanyName",
    "storageKey": null
  },
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OperatorOrderDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "OperatorOrderDetails_order"
              }
            ],
            "type": "Order",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OperatorTransferSection_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OperatorOrderDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "state",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "allowancesOrdered",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "maxPrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "created",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomerCompany",
                "kind": "LinkedField",
                "name": "customerCompany",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProviderCompany",
                "kind": "LinkedField",
                "name": "providerCompany",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isOperatorViewer",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Transaction",
                "kind": "LinkedField",
                "name": "transactions",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "price",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Operator",
                "kind": "LinkedField",
                "name": "operator",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Order",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Operator",
                "kind": "LinkedField",
                "name": "operators",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "OperatorViewer",
            "abstractKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e8424a319dd863174b64cb3c90f0739a",
    "id": null,
    "metadata": {},
    "name": "OperatorOrderDetailsQuery",
    "operationKind": "query",
    "text": "query OperatorOrderDetailsQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on Order {\n      ...OperatorOrderDetails_order\n    }\n    id\n  }\n  viewer {\n    __typename\n    ...OperatorTransferSection_viewer\n    id\n  }\n}\n\nfragment OperatorOrderDetails_order on Order {\n  id\n  state\n  allowancesOrdered\n  allowancesFilled\n  maxPrice\n  created\n  customerCompany {\n    registeredCompanyName\n    id\n  }\n  providerCompany {\n    registeredCompanyName\n    id\n  }\n  ...OperatorTransactionsSection_order\n  ...OperatorTransferSection_order\n}\n\nfragment OperatorTransactionsSection_order on Order {\n  id\n  state\n  isOperatorViewer\n  allowancesOrdered\n  allowancesFilled\n  transactions {\n    id\n    allowancesFilled\n    price\n  }\n}\n\nfragment OperatorTransferSection_order on Order {\n  id\n  state\n  isOperatorViewer\n  operator {\n    id\n  }\n}\n\nfragment OperatorTransferSection_viewer on OperatorViewer {\n  operators {\n    id\n    firstName\n    lastName\n  }\n}\n"
  }
};
})();

(node as any).hash = "a4022b99cb60a59ba2c40ef354a34eb8";

export default node;
