import { Box, Card, Stack, Typography } from '@mui/material'

import { Suspense, useState } from 'react'
import SearchBox from '@/components/SearchBox'
import TripsTable from './TripsTable'
import PriceCard from '@/components/PriceCard'
import LoadingDataGrid from '@/components/loading/LoadingDataGrid'

export default function Trips() {
    const [searchTerm, setSearchTerm] = useState<string>('')
    return (
        <Box>
            <PriceCard />
            <Typography variant="h3" sx={{ my: 4 }}>
                Trips
            </Typography>
            <Card sx={{ mb: 3 }}>
                <Stack direction="column" sx={{ p: 3 }}>
                    <SearchBox value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                </Stack>
                <Suspense fallback={<LoadingDataGrid />}>
                    <TripsTable searchTerm={searchTerm} />
                </Suspense>
            </Card>
        </Box>
    )
}
