import { PaletteMode } from '@mui/material'
import { common } from '@mui/material/colors'
import { alpha } from '@mui/material/styles'

interface Color {
    lightest: string
    light: string
    main: string
    dark: string
    darkest: string
    contrastText: string
}

const withAlphas = (color: Color) => {
    return {
        ...color,
        alpha4: alpha(color.main, 0.04),
        alpha8: alpha(color.main, 0.08),
        alpha12: alpha(color.main, 0.12),
        alpha30: alpha(color.main, 0.3),
        alpha50: alpha(color.main, 0.5),
    }
}

export const neutral = {
    50: '#F8F9FA',
    100: '#F3F4F6',
    200: '#E5E7EB',
    300: '#D2D6DB',
    400: '#9DA4AE',
    500: '#6C737F',
    600: '#4D5761',
    700: '#2F3746',
    800: '#1C2536',
    900: '#111927',
}

export const primary = withAlphas({
    lightest: '#F6FEF9',
    light: '#EDFCF2',
    main: '#16B364',
    dark: '#087443',
    darkest: '#084C2E',
    contrastText: '#FFFFFF',
})

export const success = withAlphas({
    lightest: '#F0FDF9',
    light: '#3FC79A',
    main: '#10B981',
    dark: '#0B815A',
    darkest: '#134E48',
    contrastText: '#ffffff',
})

export const info = withAlphas({
    lightest: '#ECFDFF',
    light: '#CFF9FE',
    main: '#06AED4',
    dark: '#0E7090',
    darkest: '#164C63',
    contrastText: '#ffffff',
})

export const warning = withAlphas({
    lightest: '#FFFAEB',
    light: '#FEF0C7',
    main: '#F79009',
    dark: '#B54708',
    darkest: '#7A2E0E',
    contrastText: '#ffffff',
})

export const error = withAlphas({
    lightest: '#FEF3F2',
    light: '#FEE4E2',
    main: '#F04438',
    dark: '#B42318',
    darkest: '#7A271A',
    contrastText: '#ffffff',
})

export interface Palette {
    action: {
        active: string
        disabled: string
        disabledBackground: string
        focus: string
        hover: string
        selected: string
    }
    background: {
        default: string
        paper: string
    }
    divider: string
    error: Color
    info: Color
    mode: PaletteMode
    neutral: {
        [key: number]: 'string'
    }
    primary: Color
    success: Color
    text: {
        primary: string
        secondary: string
        disabled: string
    }
    warning: Color
}

export function createPalette() {
    return {
        action: {
            active: neutral[500],
            disabled: alpha(neutral[900], 0.38),
            disabledBackground: alpha(neutral[900], 0.12),
            focus: alpha(neutral[900], 0.16),
            hover: alpha(neutral[900], 0.04),
            selected: alpha(neutral[900], 0.12),
        },
        background: {
            default: neutral[50],
            paper: common.white,
        },
        divider: '#F2F4F7',
        error,
        info,
        mode: 'light' as PaletteMode,
        neutral,
        primary,
        success,
        text: {
            primary: neutral[900],
            secondary: neutral[500],
            disabled: alpha(neutral[900], 0.38),
        },
        warning,
    } as Palette
}
