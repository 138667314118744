/**
 * @generated SignedSource<<156e193230152bb5f82b120cedd37511>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrderButton_viewer$data = {
  readonly isMarketOpen: boolean;
  readonly " $fragmentType": "OrderButton_viewer";
};
export type OrderButton_viewer$key = {
  readonly " $data"?: OrderButton_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrderButton_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderButton_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isMarketOpen",
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": "__isViewer"
};

(node as any).hash = "1b58c39c744408679eb806a7921474af";

export default node;
