/**
 * @generated SignedSource<<41c4630be3aafacc23e17d8bec54b19b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DashboardOperatorQuery$variables = {};
export type DashboardOperatorQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"IncomingOrdersTable_viewer" | "OpenOrdersTable_viewer">;
  } | null;
};
export type DashboardOperatorQuery = {
  response: DashboardOperatorQuery$data;
  variables: DashboardOperatorQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": "CREATED"
},
v3 = {
  "kind": "Literal",
  "name": "orderDir",
  "value": "DESC"
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  },
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "kind": "Literal",
    "name": "stateFilter",
    "value": "WAITING"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allowancesOrdered",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "maxPrice",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 50
  },
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "kind": "Literal",
    "name": "selfAssignedFilter",
    "value": true
  },
  {
    "kind": "Literal",
    "name": "stateFilter",
    "value": "ACCEPTED"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardOperatorQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "IncomingOrdersTable_viewer"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OpenOrdersTable_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DashboardOperatorQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": "incomingOrders",
                "args": (v4/*: any*/),
                "concreteType": "OrderConnection",
                "kind": "LinkedField",
                "name": "orders",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrderEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Order",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/)
                ],
                "storageKey": "orders(first:100,orderBy:\"CREATED\",orderDir:\"DESC\",stateFilter:\"WAITING\")"
              },
              {
                "alias": "incomingOrders",
                "args": (v4/*: any*/),
                "filters": [
                  "stateFilter",
                  "orderBy",
                  "orderDir"
                ],
                "handle": "connection",
                "key": "IncomingOrdersTable_incomingOrders",
                "kind": "LinkedHandle",
                "name": "orders"
              },
              {
                "alias": "openOrders",
                "args": (v9/*: any*/),
                "concreteType": "OrderConnection",
                "kind": "LinkedField",
                "name": "orders",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrderEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Order",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "allowancesFilled",
                            "storageKey": null
                          },
                          (v6/*: any*/),
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/)
                ],
                "storageKey": "orders(first:50,orderBy:\"CREATED\",orderDir:\"DESC\",selfAssignedFilter:true,stateFilter:\"ACCEPTED\")"
              },
              {
                "alias": "openOrders",
                "args": (v9/*: any*/),
                "filters": [
                  "selfAssignedFilter",
                  "stateFilter",
                  "orderBy",
                  "orderDir"
                ],
                "handle": "connection",
                "key": "OpenOrdersTable_openOrders",
                "kind": "LinkedHandle",
                "name": "orders"
              }
            ],
            "type": "OperatorViewer",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "200ba6a5596261504d9c287f0ecfa567",
    "id": null,
    "metadata": {},
    "name": "DashboardOperatorQuery",
    "operationKind": "query",
    "text": "query DashboardOperatorQuery {\n  viewer {\n    __typename\n    ...IncomingOrdersTable_viewer\n    ...OpenOrdersTable_viewer\n    id\n  }\n}\n\nfragment IncomingOrdersTable_viewer on OperatorViewer {\n  incomingOrders: orders(first: 100, stateFilter: WAITING, orderBy: CREATED, orderDir: DESC) {\n    edges {\n      node {\n        id\n        allowancesOrdered\n        maxPrice\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment OpenOrdersTable_viewer on OperatorViewer {\n  openOrders: orders(first: 50, selfAssignedFilter: true, stateFilter: ACCEPTED, orderBy: CREATED, orderDir: DESC) {\n    edges {\n      node {\n        id\n        allowancesOrdered\n        allowancesFilled\n        maxPrice\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "8fb3d97902a134c4dce555696ea4e0d6";

export default node;
