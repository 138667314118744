import { Box, Button, Card, CircularProgress, IconButton, Stack, SvgIcon, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useLoaderData, useNavigate, useParams } from 'react-router-dom'
import formatEuro from '@/utils/formatEuro'
import { ORDERS_PATH } from '@/config'
import RemainingTimeTicker from '@/components/RemainingTimeTicker'
import { Suspense, useState } from 'react'
import OperatorTransferSection from './OperatorTransferSection.tsx'
import OperatorTransactionsSection from './OperatorTransactionsSection.tsx'
import { graphql, loadQuery, PreloadedQuery, useFragment, useMutation, usePreloadedQuery } from 'react-relay'
import OperatorOrderDetailsQueryGraphql, {
    OperatorOrderDetailsQuery,
} from '@/__generated__/OperatorOrderDetailsQuery.graphql'
import { OperatorOrderDetailsAcceptOrderMutation } from '@/__generated__/OperatorOrderDetailsAcceptOrderMutation.graphql.ts'
import { OperatorOrderDetailsRejectOrderMutation } from '@/__generated__/OperatorOrderDetailsRejectOrderMutation.graphql.ts'
import { OperatorOrderDetails_order$key } from '@/__generated__/OperatorOrderDetails_order.graphql.ts'
import RelayEnvironment from '@/RelayEnvironment.ts'
import { toGlobalId } from '@/utils/relay.ts'

function Details() {
    const { id } = useParams()
    const { orderDetailsQueryRef } = useLoaderData() as {
        orderDetailsQueryRef: PreloadedQuery<OperatorOrderDetailsQuery>
    }
    const { node, viewer } = usePreloadedQuery(
        graphql`
            query OperatorOrderDetailsQuery($id: ID!) {
                node(id: $id) {
                    ... on Order {
                        ...OperatorOrderDetails_order
                    }
                }
                viewer {
                    ...OperatorTransferSection_viewer
                }
            }
        `,
        orderDetailsQueryRef,
    )

    const order = useFragment<OperatorOrderDetails_order$key>(
        graphql`
            fragment OperatorOrderDetails_order on Order {
                id
                state
                allowancesOrdered
                allowancesFilled
                maxPrice
                created
                customerCompany {
                    registeredCompanyName
                }
                providerCompany {
                    registeredCompanyName
                }
                ...OperatorTransactionsSection_order
                ...OperatorTransferSection_order
            }
        `,
        node,
    )

    const [isError, setIsError] = useState(false)

    const [acceptOrder, isAcceptingOrder] = useMutation<OperatorOrderDetailsAcceptOrderMutation>(graphql`
        mutation OperatorOrderDetailsAcceptOrderMutation($orderId: ID!) {
            acceptOrder(input: { orderId: $orderId }) {
                order {
                    ...OperatorOrderDetails_order
                }
            }
        }
    `)

    const [rejectOrder, isRejectingOrder] = useMutation<OperatorOrderDetailsRejectOrderMutation>(graphql`
        mutation OperatorOrderDetailsRejectOrderMutation($orderId: ID!) {
            rejectOrder(input: { orderId: $orderId }) {
                order {
                    ...OperatorOrderDetails_order
                }
            }
        }
    `)

    function handleAccept() {
        if (!order) {
            return
        }
        setIsError(false)
        return acceptOrder({
            variables: { orderId: order.id },
            onCompleted: (data, errors) => {
                if (errors || !data.acceptOrder?.order) {
                    setIsError(true)
                }
            },
            onError: () => {
                setIsError(true)
            },
        })
    }

    function handleReject() {
        if (!order) {
            return
        }
        setIsError(false)
        return rejectOrder({
            variables: { orderId: order.id },
            onCompleted: (data, errors) => {
                if (errors || !data.rejectOrder?.order) {
                    setIsError(true)
                }
            },
            onError: () => {
                setIsError(true)
            },
        })
    }

    function handleTimeout() {
        setTimeout(function () {
            loadQuery(
                RelayEnvironment,
                OperatorOrderDetailsQueryGraphql,
                { id: toGlobalId('Order', id || '') },
                { fetchPolicy: 'store-and-network' },
            )
        }, 3000)
    }

    if (!order || !viewer) {
        return null
    }

    const companyName = order.customerCompany?.registeredCompanyName || order.providerCompany?.registeredCompanyName
    const allowancesLeft = (order.allowancesOrdered || 0) - (order.allowancesFilled || 0)

    if (order.state === 'REJECTED') {
        return (
            <Box sx={{ flex: 1 }}>
                <Typography variant="h5" sx={{ mb: 2 }}>
                    Order of {order.allowancesOrdered} EUAs was rejected
                </Typography>
            </Box>
        )
    }

    if (order.state === 'WAITING') {
        return (
            <Box sx={{ flex: 1 }}>
                <Typography variant="h5" mb={6}>
                    {companyName} is asking for {order.allowancesOrdered} EUAs at a {formatEuro(order.maxPrice)} max
                    price
                </Typography>
                {isError ? (
                    <Typography variant="subtitle2" color="error">
                        Something went wrong. Please try again.
                    </Typography>
                ) : null}
                <Stack direction="row" gap={2} width="100%" justifyContent="center" my={2}>
                    <Button
                        variant="contained"
                        onClick={handleAccept}
                        disabled={isAcceptingOrder}
                        sx={{ width: '6rem' }}
                    >
                        {isAcceptingOrder ? <CircularProgress color="inherit" size="1.5rem" /> : 'Accept'}
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleReject}
                        disabled={isRejectingOrder}
                        sx={{ width: '6rem' }}
                    >
                        {isRejectingOrder ? <CircularProgress color="inherit" size="1.5rem" /> : 'Reject'}
                    </Button>
                </Stack>
                <Typography variant="h5">Time left to confirm</Typography>
                <Typography variant="h5" sx={{ mb: 2 }}>
                    <RemainingTimeTicker
                        date={order.created}
                        handleTimeout={handleTimeout}
                        expiredMessage={
                            <>
                                <div>00:00</div>
                                <small>This order will be rejected automatically</small>
                            </>
                        }
                    />
                </Typography>
            </Box>
        )
    }

    if (order.state === 'ACCEPTED') {
        return (
            <Box sx={{ flex: 1 }}>
                <Typography variant="h5">
                    Confirmed {companyName} order of {order.allowancesOrdered} EUAs at max price{' '}
                    {formatEuro(order.maxPrice)}
                </Typography>
                <Typography variant="h5" sx={{ my: 6 }}>
                    Buy {allowancesLeft} EUAs to complete the order
                </Typography>
                <OperatorTransactionsSection order={order} />
                <OperatorTransferSection order={order} viewer={viewer} />
            </Box>
        )
    }

    if (order.state === 'COMPLETED') {
        return (
            <Box sx={{ flex: 1 }}>
                <Typography variant="h5" sx={{ mb: 2 }}>
                    Order of {order.allowancesOrdered} EUAs was completed
                </Typography>
                <OperatorTransactionsSection order={order} />
            </Box>
        )
    }
}

export default function OrderDetailsWithLoading() {
    const navigate = useNavigate()
    return (
        <Suspense
            fallback={
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '3rem',
                    }}
                >
                    <CircularProgress color="inherit" />
                </div>
            }
        >
            <Card sx={{ p: 5 }}>
                <IconButton
                    onClick={() => navigate(ORDERS_PATH)}
                    sx={{
                        mr: 0.5,
                        my: 2,
                    }}
                >
                    <SvgIcon fontSize="small">
                        <ArrowBackIcon />
                    </SvgIcon>
                </IconButton>
                <Box
                    sx={{
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: 'min(calc(100vh - 100px), 500px)',
                        maxWidth: 600,
                        margin: 'auto',
                        justifyContent: 'space-between',
                    }}
                >
                    <Details />
                </Box>
            </Card>
        </Suspense>
    )
}
