/**
 * @generated SignedSource<<9711d65f95e860f40192cc21a191cb9c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OperatorTransferSectionMutation$variables = {
  operatorId: string;
  orderId: string;
};
export type OperatorTransferSectionMutation$data = {
  readonly transferOperator: {
    readonly order: {
      readonly " $fragmentSpreads": FragmentRefs<"OperatorOrderDetails_order">;
    };
  } | null;
};
export type OperatorTransferSectionMutation = {
  response: OperatorTransferSectionMutation$data;
  variables: OperatorTransferSectionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "operatorId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderId"
},
v2 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "operatorId",
        "variableName": "operatorId"
      },
      {
        "kind": "Variable",
        "name": "orderId",
        "variableName": "orderId"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allowancesFilled",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "registeredCompanyName",
    "storageKey": null
  },
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OperatorTransferSectionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "TransferOperatorPayload",
        "kind": "LinkedField",
        "name": "transferOperator",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Order",
            "kind": "LinkedField",
            "name": "order",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "OperatorOrderDetails_order"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "OperatorTransferSectionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "TransferOperatorPayload",
        "kind": "LinkedField",
        "name": "transferOperator",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Order",
            "kind": "LinkedField",
            "name": "order",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "state",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "allowancesOrdered",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "maxPrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "created",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomerCompany",
                "kind": "LinkedField",
                "name": "customerCompany",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProviderCompany",
                "kind": "LinkedField",
                "name": "providerCompany",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isOperatorViewer",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Transaction",
                "kind": "LinkedField",
                "name": "transactions",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "price",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Operator",
                "kind": "LinkedField",
                "name": "operator",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0ed5599433001013be6bda28521ea8c4",
    "id": null,
    "metadata": {},
    "name": "OperatorTransferSectionMutation",
    "operationKind": "mutation",
    "text": "mutation OperatorTransferSectionMutation(\n  $orderId: ID!\n  $operatorId: ID!\n) {\n  transferOperator(input: {orderId: $orderId, operatorId: $operatorId}) {\n    order {\n      ...OperatorOrderDetails_order\n      id\n    }\n  }\n}\n\nfragment OperatorOrderDetails_order on Order {\n  id\n  state\n  allowancesOrdered\n  allowancesFilled\n  maxPrice\n  created\n  customerCompany {\n    registeredCompanyName\n    id\n  }\n  providerCompany {\n    registeredCompanyName\n    id\n  }\n  ...OperatorTransactionsSection_order\n  ...OperatorTransferSection_order\n}\n\nfragment OperatorTransactionsSection_order on Order {\n  id\n  state\n  isOperatorViewer\n  allowancesOrdered\n  allowancesFilled\n  transactions {\n    id\n    allowancesFilled\n    price\n  }\n}\n\nfragment OperatorTransferSection_order on Order {\n  id\n  state\n  isOperatorViewer\n  operator {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "92462359106895eb3b311b20d98d572f";

export default node;
