import { Box, Card, Typography } from '@mui/material'
import { Outlet, useSearchParams } from 'react-router-dom'

import aitherLogo from '/aither.png'
import vShipsLogo from '/v-ship_logo_green.svg'

export default function Login() {
    const [searchParams] = useSearchParams()
    const isVShips = searchParams.get('v') || ''
    const brand = isVShips ? 'Ships' : 'Aither'

    return (
        <Box
            sx={{
                display: 'flex',
                height: '100vh',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'background.default',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top center',
                backgroundImage: 'url("/aither/static/gradient-bg.svg")',
            }}
        >
            <Card
                sx={{
                    backgroundColor: 'background.paper',
                    maxWidth: 550,
                    px: 3,
                    pb: 5,
                    pt: 10,
                    width: '100%',
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
                    <img
                        style={{ height: 40, marginRight: '10px', marginTop: '8px' }}
                        src={isVShips ? vShipsLogo : aitherLogo}
                        className="logo"
                        alt={`${brand} logo`}
                    />
                    <Typography variant="h1" color="common.black" sx={{ fontSize: 60, fontWeight: '600' }}>
                        {brand}
                    </Typography>
                </Box>
                <Typography sx={{ textAlign: 'center', mb: 4 }} variant="h5">
                    EUA Compliance
                </Typography>
                <Outlet />
            </Card>
        </Box>
    )
}
