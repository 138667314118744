import getTimeLeft from '@/utils/getTimeLeft'
import { useCallback, useEffect, useRef, useState } from 'react'

export default function RemainingTimeTicker({
    date,
    handleTimeout,
    expiredMessage = null,
}: {
    date: string
    handleTimeout: () => unknown
    expiredMessage?: React.ReactNode
}) {
    const timeoutCalled = useRef(false)

    const callback = useCallback(() => {
        !timeoutCalled.current && handleTimeout()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [remaining, setRemaining] = useState(getTimeLeft(date) || null)

    useEffect(() => {
        const interval = setInterval(() => {
            const timeLeft = getTimeLeft(date)
            setRemaining(timeLeft || null)
            if (!timeLeft && callback) {
                callback()
                timeoutCalled.current = true
            }
        }, 1000)
        return () => clearInterval(interval)
    }, [date, callback])

    if (remaining) {
        return <span>{remaining}</span>
    }
    return expiredMessage
}
