export function fromGlobalId(globalId: string) {
    const tokens = atob(globalId).split(':')
    return {
        id: tokens[1],
        type: tokens[0],
    }
}

export function toGlobalId(type: string, id: string) {
    return btoa(type + ':' + id)
}
